// noinspection DuplicatedCode
import React, {
    useContext,
    useEffect,
    useState
} from 'react';
// import clsx from 'clsx';
import 'date-fns';
import {Link as RouterLink} from 'react-router-dom';

import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import {Divider, Button, Typography} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Title from '../dashboard/Title';
import GameSelector from '../formSubcomponents/GameSelector';
import {UserContext} from "../../auth/UserContext";
import SoundUploader from "../formSubcomponents/SoundUploader";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        marginBottom: '64px'
    },
    formItem: {
        width: '100%',
        marginBottom: '20px',
        backgroundColor: '#d3e2ff',

    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        backgroundColor: '#b0cdff',
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    secondaryButton: {},
    readOnly: {
        width: 500,
    },
    buttonField: {
        backgroundColor: '#d3e2ff',
        padding: theme.spacing(2),
        display: 'flex',
    }
}));

const AddCollectionFormPage = ({isEditMode,   data, onSave, onDelete}) => {
    const {user, role} = useContext(UserContext);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [createdDate, setCreatedDate] = useState(new Date());
    const [games, setGames] = useState([]);
    const [isPublic, setIsPublic] = useState(false);
    const [isAuthed, setIsAuthed] = useState(false);
    const [id, setId] = useState(null);
    /*
    createdDate,
    sfxLoop,
    sfxEnd,
     */
    const [sfxLoop, setSfxLoop] = useState('');
    const [sfxEnd, setSfxEnd] = useState('');


    const classes = useStyles();

    const onSaveClick = () => {
        onSave.call(this,
            {
                title,
                description,
                createdDate,
                games,
                isPublic,
                sfxLoop,
                sfxEnd,
            }
        );
    };

    const onDeleteClick = () => {
        onDelete.call(this, {
            id,
        })
    }

    const getShortFileName = (str) => {
        return str;
        // return str.split('/')[str.split('/').length -1];
    }

    useEffect(() => {
        console.log('AddCollectionFormPage::isEditMode', isEditMode, data);
        if (!data) {
            return;
        }
        if (isEditMode) {
            setTitle(data.title);
            setDescription(data.description || '');
            setCreatedDate(data.createdDate);
            setGames(data.games);
            setIsPublic(data.isPublic || false);
            setId(data._id);
            setSfxLoop(data.sfxLoop || '');
            setSfxEnd(data.sfxEnd || '');
        }
    }, [data, isEditMode]);

    useEffect(() => {
        const authorise = () => {
            if (isEditMode && data && user) {
                return user && (user.login === data.author);
            }
            if (!isEditMode) {
                return user && user.login;
            }
            return false;
        }
        setIsAuthed(authorise());
    }, [user, data]);

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12}>

                    <Paper className={classes.paper}>
                        <Title>
                            {isEditMode ? "Edit Collection" : "Add New Collection"}
                        </Title>
                        <Divider/>
                        <form className={classes.root} noValidate autoComplete="off">
                            <Grid container>
                                <Grid item xs={12}>
                                    <h3>Details</h3>
                                    <TextField
                                        disabled={!isAuthed}
                                        className={classes.formItem}
                                        label="Collection Title"
                                        value={title}
                                        onChange={
                                            (e) => {
                                                setTitle(e.target.value);
                                            }
                                        }
                                        variant="outlined"
                                    />
                                    <FormGroup
                                        style={{
                                            marginBottom: '20px'
                                        }}
                                        row>
                                        <Paper
                                            variant="outlined"
                                            elevation={0} className={classes.formItem} style={{
                                            padding: 8
                                        }}>
                                            <Typography variant="body1" gutterBottom>
                                                Public Collections are visible in the Latest Collections section to
                                                everybody.
                                                If you don't want yours to appear publicly, then set this to off.
                                            </Typography>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        disabled={!isAuthed}
                                                        checked={isPublic}
                                                        onChange={(e) => {
                                                            setIsPublic(e.target.checked);

                                                        }}
                                                        name="isPublic"
                                                        inputProps={{'aria-label': 'primary checkbox'}}
                                                    />}
                                                label="Public"
                                            />
                                        </Paper>
                                    </FormGroup>
                                    <TextField
                                        disabled={!isAuthed}
                                        className={classes.formItem}
                                        label="Description"
                                        multiline
                                        minRows={5}
                                        value={description}
                                        onChange={
                                            (e) => {
                                                setDescription(e.target.value);
                                            }
                                        }
                                        variant="outlined"
                                    />
                                    <GameSelector
                                        isAuthed={isAuthed}
                                        games={games}
                                        setGames={setGames}
                                    />
                                </Grid>
                            </Grid>

                            <Divider style={{
                                marginBottom: '12px'
                            }}/>
                            <h3>Game Picker 64</h3>
                            {
                                isEditMode && id ? (

                                    <Grid style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }} container item xs={6}>
                                        <Typography variant="body1" gutterBottom>
                                            Here is your unique GamePicker64 URL. Copy and Paste the URL into an
                                            your stream software as a Browser source. Or click Preview to see
                                            a preview in your browser.
                                            <br/>
                                            <br/>

                                        </Typography>
                                        <Grid container item xs={3}>
                                            <Paper variant="outlined" className={classes.buttonField}>
                                                <TextField
                                                    style={{
                                                        marginRight: 20
                                                    }}
                                                    className={classes.readOnly}
                                                    id="standard-read-only-input"
                                                    label="URL"
                                                    defaultValue={`${process.env.REACT_APP_GAMEPICKER_URL}?id=${id}`}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                                <a
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    href={`${process.env.REACT_APP_GAMEPICKER_URL}?id=${id}`}>
                                                    <Button
                                                        style={{
                                                            width: 125,
                                                            height: '100%'
                                                        }}
                                                        variant="outlined" color="secondary"
                                                        className={classes.secondaryButton}>
                                                        Preview
                                                    </Button>
                                                </a>
                                            </Paper>
                                        </Grid>


                                        <h4>Custom Sounds</h4>
                                        <h5>SFX Loop</h5>
                                        <SoundUploader
                                            initialVal={data.sfxLoop && getShortFileName(data.sfxLoop)}
                                            prefix="sound-loop" setFileUrl={(val) => {
                                            setSfxLoop(val);
                                            console.log('uploader val=', val);
                                        }}/>
                                        <h5>SFX End</h5>
                                        <SoundUploader
                                            initialVal={data.sfxEnd && getShortFileName(data.sfxEnd)}
                                            prefix="sound-end" setFileUrl={(val) => {
                                            setSfxEnd(val);
                                            console.log('uploader val=', val);
                                        }}/>
                                    </Grid>

                                ) : <Typography variant="body1" gutterBottom style={{
                                    marginBottom: '12px'
                                }}>
                                    Save your collection to create a Game Picker with your selected games!
                                </Typography>
                            }
                            <Divider style={{
                                marginBottom: '64px'
                            }}/>
                            <Grid item xs={12}>
                                {
                                    isAuthed ? (
                                        <Grid style={{
                                            justifyContent: 'space-between'
                                        }} container item xs={12}>
                                            <Button
                                                style={{
                                                    width: '50%'
                                                }}
                                                size="large"
                                                variant="contained"
                                                color="primary"
                                                onClick={onSaveClick}
                                            >
                                                {"Save"}
                                            </Button>
                                            {
                                                isEditMode ? <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={onDeleteClick}
                                                >
                                                    {"Delete"}
                                                </Button> : null

                                            }
                                        </Grid>

                                    ) : <Typography variant="body1" gutterBottom>
                                        You must be logged in to Create or Edit a Collection
                                    </Typography>
                                }
                            </Grid>
                        </form>

                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

export default AddCollectionFormPage;
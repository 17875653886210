/**
 * @method hexToDec
 * @param strVal
 * @returns {number}
 */
export const hexToDec = (strVal) => {
  return parseInt(strVal, 16);
}

/**
 * @method getCoverImg
 * @param game
 * @returns {string|*|null}
 */
export const getCoverImg = (game) => {
  if (game.useCover === false) {
    return null;
  }
  if (game.coverImageUrl) {
    if (game.coverImageUrl.split('/')[1].toLowerCase() === 'uploads') {
      return process.env.REACT_APP_SERVER_URL + game.coverImageUrl;
    }
    return game.coverImageUrl;
  }
  if (game && game.covers) {
    const coverIndex = game.coverIndex || 0;
    return game.covers[coverIndex];
  }
  if (game && game.thumbnail) {
    return game.thumbnail;
  }
  return null;
}

export const insertLineBreaks = (str) => {
  if (!str) {
    return;
  }
  return str.replace(/\n/g, "<br />")
};

/**
 * @method safeVar
 * @param object
 * @param propertyName
 * @param defaultValue
 * @returns {*}
 */
export const safeVar = (object, propertyName, defaultValue) => {
  if (!object) {
    return;
  }
  if (Array.isArray(propertyName)) {
    return propertyName.reduce((o, p) => safeVar(o, p, defaultValue), object);
  }
  const objectSafe = object || {};
  return objectSafe[propertyName] || defaultValue;
};
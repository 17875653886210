import React, {useEffect, useState} from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import BasicBytes from './BasicBytes';
import StreamsSummary from './StreamsSummary';
import UpcomingStream from "./UpcomingStream";
import {getStreams} from "../streams/api";
import {sortByDate} from "../streams/utils";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  loginLink: {
    display: 'flex',
    alignItems: 'center',
  },
  profileImg: {
    width: '50px',
    height: '50px',
    clipPath: 'circle(25px at center)'
  },
  drawerPaper: {
    backgroundColor: 'white',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    minHeight: '90vh',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    backgroundColor: '#b0cdff',
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 360,
  },
}));

const MainDash = () => {

  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [streamData, setStreamData] = useState(null);

  useEffect(() => {
    let streams;
    getStreams().then(res => {
      streams = res.data.streams;
      console.log('streams', streams);
      const currentDate = new Date();
      const sortedStreams = sortByDate(streams, true);
      for (let i = 0; i < sortedStreams.length; i++) {
        const streamDate = new Date(sortedStreams[i].streamDate);
        if (streamDate - currentDate >= 0) {
          setStreamData(sortedStreams[i]);
          return;
        }
      }
    });
  }, []);

  useEffect(() => {
    console.log('upcoming streamData=', streamData);
  }, [streamData]);

  return (
    <Container maxWidth="lg" className={classes.container}>
    <Grid container spacing={3}>
      {/* Chart */}
      <Grid item xs={12} md={8} lg={9}>
        <Paper
            className={fixedHeightPaper}>
          {
            streamData? <UpcomingStream data={streamData} /> : null
          }
        </Paper>
      </Grid>
      {/* Recent Deposits */}
      <Grid item xs={12} md={4} lg={3}>
        <Paper className={fixedHeightPaper}>
          <BasicBytes />
        </Paper>
      </Grid>
      {/* Recent Orders */}
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <StreamsSummary />
        </Paper>
      </Grid>
    </Grid>
  </Container>
  )

};

export default MainDash;
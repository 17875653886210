import React, {useContext} from 'react';
import {Link as RouterLink} from 'react-router-dom';

import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TvIcon from '@material-ui/icons/Tv';
import AssignmentIcon from '@material-ui/icons/Assignment';

import "./list-items.scss";
import {UserContext} from "../../auth/UserContext";

export const MainListItems = () => {
  const {renderIfAdmin} = useContext(UserContext);
  return (
    <div className="main-list">
      <Link component={RouterLink} to="/">
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon/>
          </ListItemIcon>
          <ListItemText primary="Dashboard"/>
        </ListItem>
      </Link>
      <Link component={RouterLink} to="/streams">
        <ListItem button>
          <ListItemIcon>
            <TvIcon/>
          </ListItemIcon>
          <ListItemText primary="Streams"/>
        </ListItem>
      </Link>
      <Link component={RouterLink} to="/view-collections">
        <ListItem button>
          <ListItemIcon>
            <TvIcon/>
          </ListItemIcon>
          <ListItemText primary="Collections"/>
        </ListItem>
      </Link>
      {
        renderIfAdmin(
          <>
            <Link component={RouterLink} to="/news">
              <ListItem button>
                <ListItemIcon>
                  <TvIcon/>
                </ListItemIcon>
                <ListItemText className="todo" primary="News"/>
              </ListItem>
            </Link>
            <Link component={RouterLink} to="/leaderboard">
              <ListItem button>
                <ListItemIcon>
                  <TvIcon/>
                </ListItemIcon>
                <ListItemText className="todo" primary="Leaderboards"/>
              </ListItem>
            </Link>
            <Link component={RouterLink} to="/games">
              <ListItem button>
                <ListItemIcon>
                  <TvIcon/>
                </ListItemIcon>
                <ListItemText className="todo" primary="Game Picks"/>
              </ListItem>
            </Link>
            <Link component={RouterLink} to="/overlays">
              <ListItem button>
                <ListItemIcon>
                  <TvIcon/>
                </ListItemIcon>
                <ListItemText className="todo" primary="Overlays"/>
              </ListItem>
            </Link>
            <Link component={RouterLink} to="/new-games">
              <ListItem button>
                <ListItemIcon>
                  <TvIcon/>
                </ListItemIcon>
                <ListItemText className="todo" primary="New Games"/>
              </ListItem>
            </Link>
          </>
        )
      }
    </div>
  )
}

export const SecondaryListItems = () => {
  const {renderIfAdmin} = useContext(UserContext);
  return (
    <div className="second-list">
        <>
          <ListSubheader inset>Stream Fun</ListSubheader>
          <Link component={RouterLink} to="/sfx">
            <ListItem button>
              <ListItemIcon>
                <AssignmentIcon/>
              </ListItemIcon>
              <ListItemText primary="SFX"/>
            </ListItem>
          </Link>
          <Link component={RouterLink} to="/mix-tapes">
            <ListItem button>
              <ListItemIcon>
                <AssignmentIcon/>
              </ListItemIcon>
              <ListItemText primary="Mix Tapes"/>
            </ListItem>
          </Link>
          {
            renderIfAdmin(
              <>
                <ListItem button>
                  <ListItemIcon>
                    <AssignmentIcon/>
                  </ListItemIcon>
                  <ListItemText className="todo" primary="!Raid over Moscow"/>
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <AssignmentIcon/>
                  </ListItemIcon>
                  <ListItemText className="todo" primary="Three"/>
                </ListItem>
              </>
            )
          }
        </>
    </div>
  )
}

export const C64GameDevListItems = () => {
  // const {renderIfAdmin} = useContext(UserContext);
  return (
      <div className="second-list">
        <>
          <ListSubheader inset>C64 Gamedev</ListSubheader>
          <Link component={RouterLink} to="/mem-mapper">
            <ListItem button>
              <ListItemIcon>
                <TvIcon/>
              </ListItemIcon>
              <ListItemText primary="Memory Mapper"/>
            </ListItem>
          </Link>
        </>
      </div>
  )
}
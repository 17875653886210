 import React, {useContext, useEffect, useState} from 'react';
import {Link as ReactLink, useHistory} from 'react-router-dom';
import moment from "moment";

import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  makeStyles,
  Paper,
  Container,
  Divider,
} from "@material-ui/core";
import { AddCircleOutlined, DeleteForeverOutlined, EditOutlined} from "@material-ui/icons";

import Title from '../dashboard/Title';
import {getStreams, deleteStream} from './api';
import {sortByDate} from "./utils";
import {UserContext} from "../../auth/UserContext";

import "./styles.scss";

const useStyles = makeStyles((theme) => ({
  title: {},
  container: {
    minHeight: '90vh',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    backgroundColor: '#b0cdff',
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StreamsSummary() {
  const classes = useStyles();
  const history = useHistory();
  const {isAdmin, renderIfAdmin} = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [dialogueStates, setDialogStates] = useState({
    deleteDialogueOpen: false,
  });
  const [currentStreamTitle, setCurrentStreamTitle] = useState('');
  const [currentStreamId, setCurrentStreamId] = useState(null);
  
  const fetchData = async () => {
    let res;
    try {
      res = await getStreams();
      const streams = [...res.data.streams];
      const sorted = sortByDate(streams, false);
      setRows(sorted);
    } catch (e) {
      console.log('fetch streams error', e);
    }
  }

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchData();
  }, []);

  const getSummary = (str) => {
    const lines = str.split('\n', 2)[0];
    const words = lines.split(' ', 9);
    return words.join(' ');
  };
  
  const handleDeleteDialogueOpen = () => {
    setDialogStates({
      deleteDialogueOpen: true,
    });
  };
  
  const handleDeleteDialogueClose = () => {
    setDialogStates(
      {
        deleteDialogueOpen: false,
      }
    );
    setCurrentStreamId(null);
    setCurrentStreamTitle('');
  }
  
  const handleDeleteClicked = () => {
    onDeleteStream(currentStreamId).then(handleDeleteDialogueClose);
  };
  
  const onDeleteStream = async (id) => {
    try {
      const res = await deleteStream(id);
      console.log('delete stream', res);
      fetchData().then(r => () => {
        console.log('delete and fetched', r);
      });
    } catch (e) {
      console.log('Delete Error:', e);
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Dialog
            open={dialogueStates.deleteDialogueOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleDeleteDialogueClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Delete ${currentStreamTitle}`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {`${currentStreamTitle} is about to be permanently Deleted - Press Delete if you are sure.`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteDialogueClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteClicked} color="primary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Paper className={classes.paper}>
            <Title>
              Streams
            </Title>
            {isAdmin ? <ReactLink to='/add-stream'>
              <IconButton aria-label="delete">
                <AddCircleOutlined />
              </IconButton>
             
            </ReactLink> : null}
            <Divider/>

            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Games</TableCell>
                  <TableCell>{}</TableCell>
                  <TableCell>{}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  /**
                   *  id: ID!
                   streamTitle: String
                   streamDescription: String
                   streamDate: String
                   streamGames: [Game]
                   */
                  <TableRow key={i}>
                    <TableCell>{
                      moment(row.streamDate).format('DD MMM YYYY; hh:mm')
                    }</TableCell>
                    <TableCell>
                      <ReactLink to={`/view-stream/${row._id}`}>
                        {row.streamTitle}
                      </ReactLink>
                    </TableCell>
                    <TableCell>{getSummary(row.streamDescription)}</TableCell>
                    <TableCell>{row.streamGames.length}</TableCell>
                    <TableCell>
                      {
                        renderIfAdmin(
                            <IconButton  onClick={() => {
                              history.push(`/stream/${row._id}`)
                            }} aria-label="edit">
                              <EditOutlined />
                            </IconButton>
                        )
                      }
                    </TableCell>
                    <TableCell>
                      {
                        renderIfAdmin(
                            <IconButton  onClick={() => {
                              setCurrentStreamId(row._id);
                              handleDeleteDialogueOpen();
                              setCurrentStreamTitle(row.streamTitle);
                            }} aria-label="delete">
                              <DeleteForeverOutlined />
                            </IconButton>
                        )
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </Container>

  );
}
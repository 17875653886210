import React, {useState, useContext} from 'react';
import {
    Switch,
    Route
} from "react-router-dom";

import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Box from '@material-ui/core/Box';

import {C64GameDevListItems, MainListItems, SecondaryListItems} from '../dashboard/listItems';
import {UserContext} from '../../auth/UserContext';
import MainDash from '../dashboard/MainDash';
import AddStreamFormContainer from '../streams/AddStreamFormContainer';
import StreamsPage from '../streams/StreamsPage';
import {Popover} from "@material-ui/core";
import IMG_CHILLI_HYPE from '../../assets/images/chilli-hype-lg.png';
import SfxPage from "../games/SfxPage";
import MixTapes from "../games/MixTapes";
import MemoryMapper from "../dashboard/MemoryMapper";
import AddCollectionFormContainer from "../collections/AddCollectionFormContainer";
import CollectionsPage from "../collections/CollectionsPage";
import ViewCollectionPage from "../collections/ViewCollectionPage";
import StreamPage from "../streams/StreamPage";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            <Link color="inherit" href="https://twitch.tv/hayesmaker64">
                ChilliOS by @hayesmaker64
            </Link>
            {' '}
            {'© '}
            {new Date().getFullYear()}
            {' '}
            <a rel="me" href="https://oldbytes.space/@hayesmaker64">Mastodon</a>
        </Typography>
    );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        textStroke: '1px white',
        textShadow: '3px 3px 0 #d95bef,-1px -1px 0 #d95bef,1px -1px 0 #d95bef, -1px 1px 0 #d95bef, 1px 1px 0 #d95bef'
    },
    loginLink: {
        display: 'flex',
        alignItems: 'center',
    },
    profileImg: {
        width: '50px',
        height: '50px',
        clipPath: 'circle(25px at center)'
    },
    drawerPaper: {
        backgroundColor: 'white',
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    popover: {
        padding: theme.spacing(1)
    },
    paper: {
        backgroundColor: '#b0cdff',
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function Layout({logout}) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [logoutAnchor, setLogoutAnchor] = useState(null);
    const {user, role} = useContext(UserContext);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleAvatarClick = (e) => {
        setLogoutAnchor(e.currentTarget);
    }

    const handlePopoverClose = () => {
        setLogoutAnchor(null);
    }

    const scopes = 'user:read:email';
    const loginUrl = "https://id.twitch.tv/oauth2/authorize"
        + `?client_id=${process.env.REACT_APP_TWITCHAPP_CLIENT_ID}`
        + `&redirect_uri=${process.env.REACT_APP_TWITCHAPP_REDIRECT_URI}`
        + `&response_type=token`
        + `&scope=${scopes}`;

    const showPopover = Boolean(logoutAnchor);
    // const popoverId = showPopover? 'simple-popover' : undefined;


    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <img src={IMG_CHILLI_HYPE} alt={`chillihype logo`} style={{
                        width: '50px'
                    }}/>
                    <Typography
                        component="h1"
                        variant="h6" color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        ChilliOS Web
                    </Typography>

                    {user ?
                        <div>
                            <img
                                onClick={handleAvatarClick}
                                title={`${user.display_name} logged in as ${role}`}
                                alt="logged in user avater"
                                src={user.profile_image_url} className={clsx(classes.profileImg)}
                            />
                            <Popover
                                open={showPopover}
                                anchorEl={logoutAnchor}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <Link onClick={logout}>
                                    <Typography className={classes.popover} sx={{p: 2}}>Sign Out</Typography>
                                </Link>

                            </Popover>
                        </div>
                        : <Link
                            className={clsx(classes.loginLink)}
                            color="inherit"
                            href={loginUrl}
                        >
                            Login
                            <ExitToAppIcon/>
                        </Link>
                    }
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
                <Divider/>
                <List>
                    <MainListItems/>
                </List>
                <Divider/>
                <List>
                    <SecondaryListItems/>
                </List>
                <Divider/>
                <List>
                    <C64GameDevListItems/>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer}/>
                <Switch>
                    <Route exact path='/'>
                        <MainDash/>
                    </Route>
                    <Route path='/add-stream'>
                        <AddStreamFormContainer/>
                    </Route>
                    <Route path='/streams'>
                        <StreamsPage/>
                    </Route>
                    <Route path='/stream/:id'>
                        <AddStreamFormContainer isEditMode={true}/>
                    </Route>
                    <Route path='/view-stream/:id'>
                        <StreamPage />
                    </Route>
                    <Route path='/sfx'>
                        <SfxPage/>
                    </Route>
                    <Route path='/mix-tapes'>
                        <MixTapes/>
                    </Route>
                    <Route path='/view-collections'>
                        <CollectionsPage/>
                    </Route>
                    <Route path='/view-collection/:id'>
                        <ViewCollectionPage />
                    </Route>
                    <Route path='/add-collection' exact={true}>
                        <AddCollectionFormContainer/>
                    </Route>
                    <Route path='/collection/view/:id'>
                        <MixTapes/>
                    </Route>
                    <Route path='/collection/:id'>
                        <AddCollectionFormContainer isEditMode={true}/>
                    </Route>
                    <Route path='/mem-mapper'>
                        <MemoryMapper/>
                    </Route>
                </Switch>
                <Box pt={4}>
                    <Copyright/>
                </Box>
            </main>
        </div>
    );
}
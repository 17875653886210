// noinspection DuplicatedCode
import React, {
    useContext,
    useEffect,
    useState
} from 'react';
import {Link as ReactLink} from "react-router-dom";
// import {Helmet} from "react-helmet";

import {
    Divider, Button,
    Container,
    Grid,
    makeStyles,
    Typography,
    Box,
    Paper,
} from "@material-ui/core";
import Title from "../dashboard/Title";
import CollectionItem from "./CollectionItem";
import {UserContext} from "../../auth/UserContext";
import {getCollections, getPrivate} from "./api";
import {getCoverImg} from "../../utils";


const useStyles = makeStyles((theme) => ({
    title: {},
    container: {
        maxWidth: '100%',
        minHeight: '90vh',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    button: {
        backgroundColor: '#c1cce4',
    },
    paper: {
        backgroundColor: '#b0cdff',
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    subtitle: {
        padding: 20,
        marginBottom: 48,
        backgroundColor: '#e0e6f5',

    }
}));

const CollectionsPage = ({}) => {
    const classes = useStyles();
    const [collections, setCollections] = useState([]);
    const [privateCollections, setPrivateCollections] = useState([]);
    const {user, role} = useContext(UserContext);

    const fetchData = async () => {
        console.log('CollectionsPage::fetchData');
        try {
            let res = await getCollections();
            return res.data;
        } catch (e) {
            console.log(`Error: ${e}`);
        }
    }

    const fetchPrivateCollections = async () => {
        try {
            let res = await getPrivate(sessionStorage.twitchOAuthToken);
            return res.data;
        } catch (e) {
            console.log(`Error: ${e}`);
        }
    }

    useEffect(() => {
        console.log('CollectionsPage::UserContext user=', user, "role=", role)
        fetchData()
            .then(data => {
            if (data && data.collections) {
                setCollections(data.collections);
                console.log('setCollections::collections=', data.collections);
            }
        });

        fetchPrivateCollections().then((data) => {
            if (data && data.collections) {
                setPrivateCollections(data.collections);
                console.log('setPrivateCollections::collections=', data.collections);
            }
        });

    }, []);



    return (
        <>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Title>
                                Game Collections
                            </Title>
                            <Grid container xs={12} item style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <Grid container xs={12} item>
                                    <Paper elevation={1} className={classes.subtitle}>
                                        <Typography variant="body1" gutterBottom>
                                            Game Collections are a unique way to group your video games by
                                            specific genre. Create lists of your favourite racing games, or platformers...
                                            or just
                                            a simple list of your favourites or least favourite video games.
                                            <br/>
                                            <br/>
                                            Once you've created a collection
                                            you may generate a "GamePicker64" page which will let you pick games randomly
                                            from your collection.
                                            <br/>
                                            <br/>
                                            Here you can view Game Collections created by others, or create your own.
                                        </Typography>
                                        <Divider style={{
                                            marginBottom: 20
                                        }}/>
                                        {
                                            user && user.login ? <ReactLink to={`/add-collection`}>
                                                <Button variant="contained"
                                                        size="small" color="primary">
                                                    Create Collection
                                                </Button>
                                            </ReactLink> : <Typography variant="body1" gutterBottom>
                                                You must be logged in to Create a Collection
                                            </Typography>
                                        }

                                    </Paper>
                                </Grid>
                                {
                                    collections.length? <h3>Latest Collections</h3> : null
                                }
                                <Grid container xs={12} item style={{
                                    marginBottom: 24,
                                    display: 'flex',
                                    gap: '22px',
                                }}>

                                    {
                                        collections.map((c, i) => {
                                            let imageUrl;
                                            if (c.games && c.games.length) {
                                                imageUrl = getCoverImg(c.games[0]);
                                            }
                                            console.log('imageUrl', imageUrl);
                                            return <CollectionItem
                                                author={c.author}
                                                id={c._id}
                                                title={c.title}
                                                key={i}
                                                description={c.description}
                                                imageUrl={imageUrl}
                                            />
                                        })
                                    }
                                </Grid>


                            </Grid>
                            {
                                privateCollections.length? <h3>Your Private Collections</h3> : null
                            }
                            <Grid container xs={12} item style={{
                                margin: 'auto',
                                display: 'flex',
                                gap: '22px',
                            }}>
                                {
                                    privateCollections.map((c, i) => {
                                        let imageUrl;
                                        if (c.games && c.games.length) {
                                            imageUrl = getCoverImg(c.games[0]);
                                        }
                                        return <CollectionItem
                                            author={c.author}
                                            id={c._id}
                                            title={c.title}
                                            key={i}
                                            description={c.description}
                                            imageUrl={imageUrl}/>
                                    })
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>

    )

}

export default CollectionsPage;
import axios from 'axios';

const serverUrl = process.env.REACT_APP_SERVER_URL;
const headers = {
    'Content-Type': 'application/json'
}

export function getBases() {
    let endpoint = `${serverUrl}/api/raid/bases`;
    return axios.get(endpoint, { headers });
}

export function postGetPonts(username) {
    let endpoint = `${serverUrl}/api/points`;
    let payload = {
        username,
    }
    return axios.post(endpoint, payload, { headers });
}

export function getSettings() {
    let endpoint = `${serverUrl}/api/settings`;
    return axios.get(endpoint, {headers});
}

export function editSettings(id, data) {
    console.log('editSettings: id=%s', id, data)
    let endpoint = `${serverUrl}/api/settings/edit`;
    let payload = {
        id,
        ...data,
    };
    return axios.post(endpoint, payload, { headers });
}
import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import Typography from '@material-ui/core/Typography';

export default function FormattedDate({component="h3", variant="h6", children}) {
    return (
        <Typography
            component={component}
            variant={variant}
            color="secondary"
            gutterBottom
        >
            {moment(children).format('LLLL')}
        </Typography>
    );
}

FormattedDate.propTypes = {
    children: PropTypes.node,
};
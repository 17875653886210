import axios from 'axios';

/**
 * *
    curl -H 'Accept: application/vnd.twitchtv.v5+json' \
    -H 'Client-ID: uo6dggojyb8d6soh92zknwmi5ej1q2' \
    -H 'Authorization: OAuth cfabdegwdoklmawdzdo98xt2fo512y' \
    -X GET 'https://api.twitch.tv/helix/users'
 * *
 * @param {string} bearerToken 
 * @returns 
 */
export function getUser(bearerToken) {
    const headers = {
        'Client-ID': process.env.REACT_APP_TWITCHAPP_CLIENT_ID,
        'Authorization': `Bearer ${bearerToken}`
    };
    const endpoint = 'https://api.twitch.tv/helix/users';
    return axios.get(endpoint, {headers});
}

/**
 * 
 * @param {string|number} userId 
 * @returns 
 */
export function authoriseUser(userId, authToken) {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const endpoint = `${serverUrl}/api/users`;
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': `OAuth ${authToken}`,
    };
    let payload = {
        userId,
    };
    return axios.post(endpoint, payload, {headers});
}
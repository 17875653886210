import axios from "axios";

const headers = {
    'Content-Type': 'application/json'
};

/**
 *
 * @param data
 */
export async function addCollection(data) {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const endpoint = `${serverUrl}/api/collections/add`;
    let payload = {
        ...data
    }
    return axios.post(endpoint, payload, {headers});
}

/**
 *
 * @param id
 * @param data
 */
export async function editCollection(id, data) {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    let endpoint = `${serverUrl}/api/collections/edit`;
    let payload = {
        id,
        ...data,
    };
    return axios.post(endpoint, payload, {headers});
}

/**
 * @param id
 */
export async function getCollection(id) {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    let endpoint = `${serverUrl}/api/collections/${id}`;
    return axios.get(endpoint, {headers});
}

export async function getPrivate(authToken) {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const endpoint = `${serverUrl}/api/collections/private`;
    const authHeaders = {
        ...headers,
        'Authorization': `OAuth ${authToken}`,
    }
    return axios.get(endpoint, {headers: authHeaders});
}

export async function getCollections() {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const endpoint = `${serverUrl}/api/collections`;
    return axios.get(endpoint, {headers});
}

export function deleteCollection(id) {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    let endpoint = `${serverUrl}/api/collections/delete`;
    let payload = {
        id,
    }
    return axios.post(endpoint, payload, {headers});
}

import React, {useState, useEffect, useContext} from 'react';
import 'date-fns';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import {Divider, Button, Popover} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import Title from '../dashboard/Title';
import {editSettings, getSettings} from '../dashboard/api';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import {UserContext} from "../../auth/UserContext";
import {getAllSfx} from "./api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    marginBottom: '64px'
  },
  formItem: {
    width: '100%',
    marginBottom: '20px',
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    backgroundColor: '#b0cdff',
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  buttonRow: {
    marginBottom: '16px',
  },
  divider: {
    marginBottom: '20px',
  },
  sfxButton: {
    margin: '12px',
    width: '200px'
  },
  sfxValueArea: {
    color: 'black'
  },
  copyButton: {
    fontSize: '12px',
    width: '100px',
    marginLeft: '10px'
  }
}));

const SfxPage = () => {
  
  const classes = useStyles();
  
  const [categories, setCategories] = useState([]);
  const [settings, setSettings] = useState(null);
  // const [allSfxData, setAllSfxData] = useState(null);
  const {isAdmin} = useContext(UserContext);

  const [openPopTitle, setOpenPopTitle] = useState(null);
  const [openPopIndex, setOpenPopIndex] = useState(-1);
  const [popAnchor, setPopAnchor] = useState(null);
  
  const [formCategoriesEnabled, setFormCategoriesEnabled] = useState(null);
  const [formGlobalEnabled, setFormGlobalEnabled] = useState(false);
  
  const fetchAllSfx = async () => {
    try {
      const res = await getAllSfx();
      if (res && res.data) {
        console.log('SfxPage::fetchAllSfx res.data', res.data);
        // setAllSfxData(res.data);
        setCategories(res.data.categories);
      }
    } catch (e) {
      console.log('ERROR: fetching all sfx data');
    }
  }
  
  const fetchSettings = async () => {
    try {
      const res = await getSettings();
      if (res && res.data) {
        console.log('SfxPage::fetchSettings res.data.settings', res.data.settings);
        setSettings(res.data.settings[0]);
      }
    } catch (e) {
      console.log('ERROR: fetch settings', e);
    }
  }
  
  useEffect(() => {
    fetchAllSfx().then(fetchSettings);
  }, []);
  
  useEffect(() => {
    if (!settings) {
      return;
    }
    const formCheckboxEnabled = [];
    settings.sfxCategories && settings.sfxCategories.forEach((cat, i) => {
      formCheckboxEnabled.push(cat.enabled);
    });
    setFormCategoriesEnabled(formCheckboxEnabled);
    setFormGlobalEnabled(settings.sfxEnabled);
  }, [settings]);
  
  const onPopOpenClick = (e, i, commandValue) => {
    setOpenPopTitle(`!sfx ${commandValue}`);
    setOpenPopIndex(i);
    setPopAnchor(e.currentTarget);
  }
  
  const onPopCloseClick = () => {
    setOpenPopTitle(null);
    setOpenPopIndex(-1);
    setPopAnchor(null);
  }
  
  const onCopyCommandClick = async (text) => {
    await navigator.clipboard.writeText(text);
    console.log(`${text} copied to clipboard`);
  }
  
  const onCheckGlobalSfxEnable = (e) => {
    const isChecked = e.target.checked;
    setFormGlobalEnabled(isChecked);
    const newSettings = {...settings}
    newSettings.sfxEnabled = isChecked;
    setSettings(newSettings);
    editSettings(settings._id, newSettings).then((res) => {
      console.log('editSettings', res);
    });
  }
  
  const onCheckSfxCategoryEnable = (e, i) => {
    
    console.log('SfxPage::onCheckSfxCategoryEnable', i, e.target.checked);
    
    const updateSettings = (isChecked) => {
      const newSettings = {...settings}
      newSettings.sfxCategories[i].enabled = isChecked;
      setSettings(newSettings);
      editSettings(settings._id, newSettings).then((res) => {
        console.log('editSettings', res);
      });
    };
    
    const isChecked = e.target.checked;
    const newArr = [...formCategoriesEnabled];
    newArr[i] = isChecked;
    setFormCategoriesEnabled(newArr);
    updateSettings(isChecked);
  };
  
  const isGlobalDisabled = () => {
    return settings && !settings.sfxEnabled;
  }
  
  const isCategoryDisabled = (value) => {
    // console.log('isCategoryDisabled', value,  settings);
    if (!settings) {
      return true;
    }
    const current = settings.sfxCategories.find((c) => {
      return c.name === value;
    });
    if (!current) {
      return;
    }
    return isGlobalDisabled() || !current.enabled;
  }
  
  return (
    <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            
            <Paper className={classes.paper}>
              <Title>
                Sound FX library
              </Title>
              <FormGroup
                style={{
                  marginBottom: '20px'
                }}
                row>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!isAdmin}
                      checked={formGlobalEnabled}
                      onChange={(e) => {
                        onCheckGlobalSfxEnable(e);
                      }}
                      name="isCurrent"
                    />}
                  label="SFX Enabled"
                />
              </FormGroup>
              
              <Divider className={classes.divider} />
  
              
              {
                categories && categories.map((category, i) => {
                  return (
                    <div key={i}>
                      <Typography component="h4" variant="h6" color="primary" gutterBottom>
                        {category.name}
                      </Typography>
  
                      <FormGroup
                        style={{
                          marginBottom: '20px'
                        }}
                        row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={!isAdmin || isGlobalDisabled()}
                              checked={formCategoriesEnabled && formCategoriesEnabled[i]}
                              onChange={(e) => {
                                onCheckSfxCategoryEnable(e, i);
                              }}
                              name="isCurrent"
                            />}
                          label="SFX Category Enabled"
                        />
                      </FormGroup>
  
                      <Grid
                        className={classes.buttonRow}
                        container
                        justify={"space-evenly"}
                        direction="row"
                        item xs={12}>
    
                        {
                          category && category.sfx.map((s, i) => {

                            console.log('SfxPage::categoryMap s', s);

                            // why doesnt this commandString work here?
                            // const commandString = `!sfx ${s.value}`;
                            
                            return (
                              <div key={i}>
                                <Button
                                  disabled={isCategoryDisabled(category.value)}
                                  title={s.description}
                                  className={classes.sfxButton}
                                  variant="contained"
                                  color="secondary"
                                  onClick={(e) => {
                                    onPopOpenClick(e, i, s.value);
                                  }}
                                >
                                  {s.label}
                                </Button>
                                <Popover
                                  open={openPopIndex === i}
                                  anchorEl={popAnchor}
                                  onClose={onPopCloseClick}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                >
                                  <div style={{
                                    padding: '10px',
                                  }}>
                                    <TextField
                                      className={classes.sfxValueArea}
                                      label="Sfx Command:"
                                      // color="textSecondary"
                                      value={openPopTitle}
                                      variant="outlined"
                                    />
                                    <Button
                                    onClick={() => {
                                      onCopyCommandClick(openPopTitle);
                                    }}
                                    className={classes.copyButton}
                                    variant="outlined"
                                    color="primary"
                                  >
                                    Copy to Clipboard
                                  </Button>
                                  </div>
                                </Popover>
                              </div>
                            )
                          })
                        }
            
  
                      </Grid>
                      
                      <Divider className={classes.divider} />
                      
                    </div>
                  )
                  
                })
              }
            </Paper>
          </Grid>
        </Grid>
    </Container>
  )
}

export default SfxPage;
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

export default function Description({component = "h3", variant = "h5", children}) {
    return (
        <Typography
            component={component}
            variant={variant}
            color="primary"
            dangerouslySetInnerHTML={{
                __html: children
            }}

            gutterBottom
        />
    );
}

Description.propTypes = {
    children: PropTypes.node,
};
import React, {useCallback, useEffect, useState} from 'react';
import 'date-fns';

import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import {Divider, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";

import Title from '../dashboard/Title';
import rpgsThumbnail from '../../assets/images/deathknights.png';

import "./mix-tapes.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardYo: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  title: {
    marginBottom: '64px'
  },
  formItem: {
    width: '100%',
    marginBottom: '20px',
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    backgroundColor: '#b0cdff',
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  buttonRow: {
    marginBottom: '16px',
  },
  divider: {
    marginBottom: '20px',
  },
  sfxButton: {
    margin: '12px',
    width: '200px'
  },
  copyButton: {
    fontSize: '12px',
    width: '100px',
    marginLeft: '10px'
  }
}));

const MixTapes = () => {
  
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  
  const mixTapeData = {
    tapes: [
      {
        downloadUrl: 'https://hay64.fra1.digitaloceanspaces.com/fantasy-rpg-mix.mp3',
        title: 'Epic Fantasy RPGs',
        description: ' 1 hour 14 minutes of epic C64 fantasy RPG HotJams! Recorded on Ultimate64 with real Stereo SIDs',
        duration: '1h 14m',
        thumbnail: '',
        tracks: [
          {
            title: 'Storm Warrior',
            duration: '04:20',
            track: 8,
            musician: 'Mark Cooksey',
            downloadUrl: 'https://assets.hayesmaker64.com/01-Storm%20Warrior.mp3',
            thumbnail: ''
          },
          {
            title: 'Rastan',
            duration: '01:36',
            track: 3,
            musician: 'Martin Galway',
            downloadUrl: 'https://assets.hayesmaker64.com/02-Rastan.mp3',
            thumbnail: ''
          },
          {
            title: 'Storm Warrior (Front Runner)',
            duration: '01:59',
            track: 1,
            musician: 'Unknown',
            downloadUrl: 'https://assets.hayesmaker64.com/03-Storm%20Warrior%20%28Front%20Runner%29.mp3',
            thumbnail: ''
          },
          {
            title: 'Golden Axe',
            duration: '04:15',
            track: 1,
            musician: 'Jeroen Tel',
            downloadUrl: 'https://assets.hayesmaker64.com/04-Golden%20Axe.mp3',
            thumbnail: ''
          },
          {
            title: 'Gauntlet III',
            duration: '02:16',
            track: 1,
            musician: 'Tim Follin',
            downloadUrl: 'https://assets.hayesmaker64.com/05-Gauntlet%20III.mp3',
            thumbnail: ''
          },
          {
            title: 'Master of Magic',
            duration: '05:21',
            track: 1,
            musician: 'Rob Hubbard',
            downloadUrl: 'https://assets.hayesmaker64.com/06-Master%20of%20Magic.mp3',
            thumbnail: ''
          },
          {
            title: 'Castle Master',
            duration: '02:57',
            track: 1,
            musician: 'Matt Furniss',
            downloadUrl: 'https://assets.hayesmaker64.com/07-Castle%20Master.mp3',
            thumbnail: ''
          },
          {
            title: 'Ghouls n Ghosts',
            duration: '06:13',
            track: 7,
            musician: 'Tim Follin',
            downloadUrl: 'https://assets.hayesmaker64.com/08-Ghouls%20n%20Ghosts%20%287%29.mp3',
            thumbnail: ''
          },
          {
            title: 'Terramex',
            duration: '03:14',
            track: 1,
            musician: 'Ben Daglish',
            downloadUrl: 'https://assets.hayesmaker64.com/09-Terramex.mp3',
            thumbnail: ''
          },
          {
            title: 'Last Ninja Dungeons',
            duration: '05:30',
            track: 9,
            musician: 'Ben Daglish',
            downloadUrl: 'https://assets.hayesmaker64.com/10-Last%20Ninja%20Dungeons.mp3',
            thumbnail: ''
          },
          {
            title: 'Spellbound',
            duration: '05:41',
            track: 1,
            musician: 'Rob Hubbard',
            downloadUrl: 'https://assets.hayesmaker64.com/11-Spellbound.mp3',
            thumbnail: ''
          },
          {
            title: 'Stormlord',
            duration: '06:48',
            track: 1,
            musician: 'Jeroen Tel & Johannes...',
            downloadUrl: 'https://assets.hayesmaker64.com/12-Stormlord.mp3',
            thumbnail: ''
          },
          {
            title: 'Future Knight',
            duration: '03:00',
            track: 1,
            musician: 'Ben Daglish',
            downloadUrl: 'https://assets.hayesmaker64.com/13-Future%20Knight.mp3',
            thumbnail: ''
          },
          {
            title: 'Mutants',
            duration: '04:10',
            track: 1,
            musician: 'Fred Gray',
            downloadUrl: 'https://assets.hayesmaker64.com/14-Mutants.mp3',
            thumbnail: ''
          },
          {
            title: 'Firelord',
            duration: '02:50',
            track: 1,
            musician: 'Ben Daglish',
            downloadUrl: 'https://assets.hayesmaker64.com/15-Firelord.mp3',
            thumbnail: ''
          },
          {
            title: 'Nemesis the Warlock',
            duration: '06:49',
            track: 1,
            musician: 'Rob Hubbard',
            downloadUrl: 'https://assets.hayesmaker64.com/16-Nemesis%20The%20Warlock.mp3',
            thumbnail: ''
          },
          {
            title: 'Times of Lore',
            duration: '08:00',
            track: 1,
            musician: 'Martin Galway',
            downloadUrl: 'https://assets.hayesmaker64.com/17-Times%20of%20Lore.mp3',
            thumbnail: ''
          },
          {
            title: 'Bonus Track - Forbidden Forest',
            duration: '01:30',
            track: 1,
            musician: 'Paul Norman',
            downloadUrl: 'https://assets.hayesmaker64.com/18-Bonus%20Track%20-%20Forbidden%20Forest.mp3',
            thumbnail: ''
          }
        ]
      }
    ]
  }
  
  const setData = useCallback(() => {
    setRows(mixTapeData.tapes[0].tracks);
  }, [mixTapeData.tapes]);
  
  useEffect(() => {
    setData();
  }, []);
  
  
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          
          <Paper className={classes.paper}>
            <Title>
              NOW That's What I Call SID Music!
            </Title>
            
            <Divider className={classes.divider}/>
            
            {
              mixTapeData.tapes.map((mix, i) => {
                
                return (
                  <Card className={classes.cardYo} key={i}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image={rpgsThumbnail}
                        title={mix.title + ' mix tape'}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          {mix.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {mix.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      {/*<Button size="small" color="primary">*/}
                      
                      <a className="resp-sharing-button__link" href="https://ctt.ac/c45Az" target="_blank"
                         rel="noopener noreferrer" aria-label="Twitter">
                        
                        <div className="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--medium">
                          <div aria-hidden="true"
                               className="resp-sharing-button__icon resp-sharing-button__icon--solidcircle">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M12 0C5.38 0 0 5.38 0 12s5.38 12 12 12 12-5.38 12-12S18.62 0 12 0zm5.26 9.38v.34c0 3.48-2.64 7.5-7.48 7.5-1.48 0-2.87-.44-4.03-1.2 1.37.17 2.77-.2 3.9-1.08-1.16-.02-2.13-.78-2.46-1.83.38.1.8.07 1.17-.03-1.2-.24-2.1-1.3-2.1-2.58v-.05c.35.2.75.32 1.18.33-.7-.47-1.17-1.28-1.17-2.2 0-.47.13-.92.36-1.3C7.94 8.85 9.88 9.9 12.06 10c-.04-.2-.06-.4-.06-.6 0-1.46 1.18-2.63 2.63-2.63.76 0 1.44.3 1.92.82.6-.12 1.95-.27 1.95-.27-.35.53-.72 1.66-1.24 2.04z"/>
                            </svg>
                          </div>
                          Tweet
                        </div>
                      </a>
                      
                      
                      {/*Share*/}
                      {/*</Button>*/}
                      {/*                      <Button size="small" color="primary">
                        Tracks
                      </Button>*/}
                      <Button size="small" color="primary">
                        <a href='https://assets.hayesmaker64.com/fantasy-rpg-mix.mp3' download="fantasy-rpg-mix-1">
                          Download
                        </a>
                      </Button>
                    </CardActions>
                  </Card>
                )
                
              })
            }
          
          
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>
              Tracks
            </Title>
            <Divider/>
            
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Track</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Artist</TableCell>
                  <TableCell>SID Track</TableCell>
                  <TableCell>Duration</TableCell>
                  <TableCell>Download</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows && rows.map((row, i) => (
                  /**
                   title: 'Storm Warrior',
                   track: 1,
                   musician: 'Mark Cooksey',
                   downloadUrl: '',
                   thumbnail: ''
                   */
                  <TableRow key={i}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.musician}</TableCell>
                    <TableCell>{row.track}</TableCell>
                    <TableCell>{row.duration}</TableCell>
                    <TableCell>
                      <Button size="small" color="primary">
                        <a href={row.downloadUrl} download={true}>
                          Download
                        </a>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default MixTapes;
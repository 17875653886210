import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red, blue } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {getCoverImg} from "../../utils";
import {Button} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 345,
        width: 345,
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    avatarLemon: {
        backgroundColor: blue[500],
    },
    avatarCustom: {
        backgroundColor: red[500],
    }
}));

export default function GameCardItem({game}) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    console.log("GameCardItem", game);

    /**
     * @todo export to utils
     * @returns {string}
     */
    const title = game && game.metadata && game.metadata.name || game.gameTitle;
    const getSubtitle = () => {
        if (game && game.metadata) {
            return `${game.metadata.publisher} (${game.metadata.year})`;
        }
        return '';
    }

    const getDescription = () => {
        if (game && game.metadata) {
            return `${game.metadata.description}`;
        }
        return '';
    }

    const getIsLemon = () => {
        return game && game.metadata;
    }

    const getLemonClass = () => {
        if (getIsLemon()) {
            return classes.avatarLemon;
        }
        return classes.avatarCustom;
    }


    const handleExpandClick = () => {
        //setExpanded(!expanded);
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={getLemonClass()}>
                        {getIsLemon()? 'L' : 'C'}
                    </Avatar>
                }
                // action={
                //     <IconButton aria-label="settings">
                //         <MoreVertIcon />
                //     </IconButton>
                // }
                title={title}
                subheader={getSubtitle()}
            />
            <CardMedia
                className={classes.media}
                image={getCoverImg(game)}
                title={`${title} Cover Art`}
            />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {getDescription()}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                {
                    getIsLemon()?  <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={`${game.gameHref}`}>
                        <Button
                            style={{
                                width: 200,
                                height: '100%'
                            }}
                            variant="outlined" color="secondary"
                            className={classes.secondaryButton}>
                            View
                        </Button>
                    </a> : null
                }


                {/*<IconButton aria-label="add to favorites">*/}
                {/*    <FavoriteIcon />*/}
                {/*</IconButton>*/}
                {/*<IconButton aria-label="share">*/}
                {/*    <ShareIcon />*/}
                {/*</IconButton>*/}

                {/*<IconButton*/}
                {/*    className={clsx(classes.expand, {*/}
                {/*        [classes.expandOpen]: expanded,*/}
                {/*    })}*/}
                {/*    onClick={handleExpandClick}*/}
                {/*    aria-expanded={expanded}*/}
                {/*    aria-label="show more"*/}
                {/*>*/}
                {/*    <ExpandMoreIcon />*/}
                {/*</IconButton>*/}
            </CardActions>
        </Card>
    );
}
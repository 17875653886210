import React, {useEffect, useState} from 'react';

import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles} from '@material-ui/core/styles';
import {TextField, Button} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        marginBottom: '64px'
    },
    formItem: {
        width: '100%',
        marginBottom: '20px',
    },
}));
const MultiValueForm = ({initialValues, setResults, defaultLabel}) => {
    const classes = useStyles();

    const [hasInitialised, setHasInitialised] = useState(false);
    const [items, setItems] = useState([{
        val: ''
    }]);

    useEffect(() => {
        if (!initialValues) {
            return;
        }
        if (hasInitialised) {
            return;
        }
        const newItems = initialValues.map((val) => {
            return {
                val,
            }
        });
        setHasInitialised(true);
        setItems(newItems);
    }, [initialValues]);


    /*
      useEffect(() => {
        if (!values || values.length === 0) {
          return;
        }
        if (hasInitialised) {
          return;
        }
        console.log("MultiValueForm::initialise:values=", values);
        const newItems = values.map((item) => {
          return {
            val: item,
          }
        });
        console.log('MultiValueForm::initialise:newItems=', newItems);
        if (newItems.length) {
          setItems(newItems);
          setHasInitialised(true);
        }
      }, [values]);
    */

    const onChange = (e, i) => {
        const newItems = [...items];
        newItems[i].val = e.target.value;
        setItems(newItems);
        setResults(newItems.map((item) => {
            return (item.val && item.val.length > 0) ? item.val && item.val.trim() : null;
        }));
    }

    // useEffect(() => {
    //   const vals = items.map((item) => {
    //     return item.val && item.val.length && item.val.trim();
    //   });
    //   setValues(vals);
    // }, [items]);

    const onAddClick = () => {
        const newItems = [...items];
        newItems.push({
            val: ''
        });
        setItems(newItems);
    }

    const onRemoveClick = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
        setResults(newItems.map((item) => {
            return (item.val && item.val.length > 0) ? item.val && item.val.trim() : null;
        }));
    }

    return (
        <div>
            {
                items.map((item, i) => {
                    return <div
                        key={i}
                        style={{
                            display: 'flex',
                        }}>
                        <TextField
                            disabled={false}
                            className={classes.formItem}
                            label={defaultLabel}
                            title={defaultLabel}
                            value={item.val}
                            onChange={(e) => {
                                onChange(e, i)
                            }}
                            variant="outlined"
                        />
                        <Button
                            onClick={() => {
                                onRemoveClick(i);
                            }}
                        >
                            <DeleteIcon />
                        </Button>
                    </div>
                })
            }
            <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={onAddClick}
            >
                {"Add"}
            </Button>
        </div>
    )
}

export default MultiValueForm;
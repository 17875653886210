import React, { useState } from 'react';

import { Typography, Button } from '@material-ui/core';

import {postCoverImage, postFile} from "./api";
import "./image-upload.scss";

/**
 * Adapted from Material UI File Upload example
 * see: https://www.bezkoder.com/material-ui-file-upload/
 *
 * @class FileUploader
 * @returns {JSX.Element}
 * @constructor
 */
const FileUploader = ({setFileUrl, prefix}) => {

    const [selectedFiles, setSelectedFiles] = useState(null);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const submitFile = () => {
        postFile(selectedFiles[0])
            .then((res) => {
                if (res && res.data) {
                    console.log('File Upload success', res.data);
                    setFileUrl(res.data.url);
                }
        })
            .catch((err) => {
                console.log('err:', err);
                setIsError(true);
                setErrorMessage(err);
            });
    };

    return (
        <div className="mg20">
            <label htmlFor={`${prefix}-btn-upload`}>
                <input
                    id={`${prefix}-btn-upload`}
                    name={`${prefix}-btn-upload`}
                    style={{display: 'none'}}
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                        console.log('e.target.files', e.target.files);
                        setSelectedFiles(e.target.files);
                    }}/>
                <Button
                    className="btn-choose"
                    variant="outlined"
                    component="span"
                >
                    Choose File
                </Button>
            </label>
            <div className="file-name">
                {selectedFiles && selectedFiles.length > 0 ? selectedFiles[0].name : null}
            </div>
            <Button
                className={`${prefix}-btn-upload`}
                color="primary"
                variant="contained"
                component="span"
                disabled={!selectedFiles}
                onClick={submitFile}>
                Submit
            </Button>

            <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
                {errorMessage}
            </Typography>
        </div>
    );
}

export default FileUploader;

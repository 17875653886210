import React, {useEffect, useContext, useState} from 'react';
import NumberFormat from 'react-number-format';

import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import {UserContext} from '../../auth/UserContext';
import {postGetPonts} from './api';

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function Deposits() {
  const classes = useStyles();
  const {user} = useContext(UserContext);
  const [points, setPoints] = useState(null);
  
  useEffect(() => {
    if (!user) {
      return;
    }
    postGetPonts(user.display_name).then((res) => {
      console.log('postGetPoints :: ', res.data);
      setPoints(res.data);
    }).catch(err => {
      console.log('postGetPoints err', err);
    });
  }, [user])

  return (
    <React.Fragment>
      <Title>Basic Bytes</Title>
      <Typography component="div" variant="h4">
        
      <NumberFormat 
        value={(points && points.points) || ''} 
        displayType={'text'} 
        thousandSeparator={true} 
        renderText={
          (value, props) => 
          <div {...props}>
            {value}
          </div>} 
      />

      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        Current Rank: {points && points.rank}
      </Typography>
      <div>
        <Link color="primary" href={`https://streamelements.com/hayesmaker64/leaderboard`} target="_blank" rel="noopener noreferrer" >
          View leaderboard
        </Link>
      </div>
    </React.Fragment>
  );
}
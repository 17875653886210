/**
 * @method sortByDate
 * @param arr
 * @param asc
 * @returns {*}
 */
export const sortByDate = (arr, asc) => {
    return arr.sort((a,b) => {
        const dateA = new Date(a.streamDate);
        const dateB = new Date(b.streamDate);
        if (asc) {
            return dateA - dateB;
        }
        return dateB - dateA
    });
}

/**
 * @method removeFutureStreams
 * @param arr
 * @returns {*}
 */
export const removeFutureStreams = (arr) => {
    const now = new Date();
    return arr.filter((s) => {
        return now - new Date(s.streamDate) >= 0;
    });
}

/**
 * @method getSummary
 * @param str
 * @returns {*}
 */
export const getSummary = (str) => {
    const lines = str.split('\n', 2)[0];
    const words = lines.split(' ', 9);
    return words.join(' ');
};
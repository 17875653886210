import React, {useEffect, useState, useCallback, useContext} from 'react';

import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/AddCircleOutlined';
import MenuIcon from '@material-ui/icons/Menu'
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
    Button,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Grid,
    makeStyles,
    Paper,
    InputBase,
    IconButton,
    Menu,
    MenuItem,
    Checkbox,
    Chip,
    FormControl,
    Select, TableHead, Typography,
} from "@material-ui/core";

import {searchGame} from '../streams/api';
import FileUploader from "./FileUploader";

const MAX_GAMES = 100;

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'transparent',
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    search: {
        width: '25%',
        backgroundColor: '#d3e2ff',
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    popover: {
        padding: theme.spacing(1)
    },
    coverImg: {
        backgroundColor: 'black',
        width: '120px',
        height: '120px',
        objectFit: 'contain',
        marginRight: '10px'
    },
    input: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        flex: 1,
        backgroundColor: '#d3e2ff',
    },
    iconButton: {
        padding: 10,
    },
    table: {
        //minWidth: 650,
        marginBottom: '30px'
    },
    rows: {
        borderBottomColor: 'rgba(0,0,0,0.42)',
    },
    chipsWrapper: {
        paddingTop: '12px',
        paddingBottom: '12px'
    },
    chips: {
        margin: '4px',
    }

}));
const serverUrl = process.env.REACT_APP_SERVER_URL;

export default function GameSelector({games, setGames, isAuthed}) {
    const classes = useStyles();

    const [searchResults, setSearchResults] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isError, setIsError] = useState(false);

    const [openUploadIndex, setOpenUploadIndex] = useState(-1);
    const [uploadAnchor, setUploadAnchor] = useState(null);

    const [openChooseCoverIndex, setOpenChooseCoverIndex] = useState(-1);
    const [chooseCoverAnchor, setChooseCoverAnchor] = useState(null);

    const menuOptions = [
        'Lemon Game',
        'Custom Game'
    ];

    const getMenuPlaceholder = () => {
        switch (selectedIndex) {
            case 1:
                return "Add Custom Game"
            default:
                return "Search Lemon64"
        }
    };

    const getSearchState = () => {
        switch (selectedIndex) {
            case 1:
                return 'isCustom';
            default:
                return 'isLemon';
        }
    }

    const handleMenuOpenClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const uploadPopClick = (e, i) => {
        setOpenUploadIndex(i);
        setUploadAnchor(e.currentTarget);
    }

    const chooseCoverPopClick = (e, i) => {
        setOpenChooseCoverIndex(i);
        setChooseCoverAnchor(e.currentTarget);
    }

    const handleChooseCoverClose = () => {
        setOpenChooseCoverIndex(-1);
        setChooseCoverAnchor(null);
    }

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleUploadPopClose = () => {
        setOpenUploadIndex(-1);
        setUploadAnchor(null);
    };

    const handleUseCoversChange = (isChecked, row, rowIndex) => {
        const newGames = [...games];
        //find by row data?
        newGames[rowIndex].useCover = isChecked;
        setGames(newGames);
    };


    const handleEnablePickChange = (isChecked, row, rowIndex) => {
        const newGames = [...games];
        newGames[rowIndex].enablePick = isChecked;
        setGames(newGames);
    };

    const handleChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearch = async (query) => {
        try {
            let res = await searchGame(query);
            setSearchResults(res.data.games);
        } catch (e) {
            console.log(e);
        }

    };

    const handleCustom = (e) => {
        const newPickedGames = [...games];
        const newGame = {
            gameHref: '',
            gameId: '',
            gameTitle: searchQuery,
            type: 'custom',
            useCover: false,
            enablePick: true,
            coverImageUrl: '',
            coverIndex: 0,
        }
        newPickedGames.push(newGame);
        setGames(newPickedGames);
    };

    const handleChipClick = (value) => {
        const newPickedGames = [...games];
        if (!newPickedGames.find((g) => {
            return g.gameTitle === value
        })) {
            let addGame = searchResults.find((g) => {
                return g.gameTitle === value;
            });

            if (newPickedGames.length >= 100) {
                return;
            }

            if (addGame) {
                newPickedGames.push({
                    ...addGame,
                    enablePick: true,
                    useCover: true,
                    type: 'lemon',
                    coverIndex: 0,
                });
                setGames(newPickedGames);
            }

            if (newPickedGames.length >= MAX_GAMES) {
                setIsError(true);
                setErrorMessage(`You've reached the limit of ${MAX_GAMES} games for this collection\nRemove some games to add more`);
            }
        }
    };

    const removeGameClick = (value, gameId) => {
        const newPickedGames = [...games];
        let removeGame = newPickedGames.findIndex((g) => {
            return g.gameTitle === value;
        });
        if (removeGame >= 0) {
            newPickedGames.splice(removeGame, 1);
        }
        setGames(newPickedGames);
    };

    function getCoverImg(game) {
        if (game.useCover === false) {
            return null;
        }

        if (game.coverImageUrl) {
            return <a
                href={`${serverUrl}${game.coverImageUrl}`} target="_blank" rel="noreferrer">
                <img
                    className={classes.coverImg}
                    src={`${serverUrl}${game.coverImageUrl}`}
                    alt="game cover"
                />
            </a>
        }
        if (game && game.covers) {
            const coverIndex = game.coverIndex || 0;
            const image = game.covers[coverIndex];
            return <img
                className={classes.coverImg}
                src={image}
                alt="game cover"
            />
        }
        if (game && game.thumbnail) {
            const image = game.thumbnail;
            return <img
                className={classes.coverImg}
                src={image}
                alt="game cover"
            />
        }
        return null;
    }

    function getAllCovers(rowIndex, game) {
        if (!game.covers) {
            return;
        }
        return game.covers.map((url, i) => {
            return (
                <Button
                    key={i}
                    onClick={() => {
                        const newGames = [...games];
                        newGames[rowIndex].coverIndex = i;
                        setGames(newGames);
                        handleChooseCoverClose();
                    }}>
                    <img
                        className={classes.coverImg}
                        src={url}
                        alt="game cover"
                    />
                </Button>
            )
        })

    }

    return (
        <div style={{
            marginBottom: '12px'
        }}>
            <h3>Game Selector</h3>

            <Grid>
                <Grid>
                    <Typography variant="body1" gutterBottom style={{
                        marginBottom: 12
                    }}>
                        Search Lemon then click on one of the results to add a game. If your game is
                        not in Lemon, then choose "Custom Game".<br/><br/>

                        To search: enter a name and click on the search icon or press enter.
                    </Typography>
                    <Paper className={classes.search}>
                        <IconButton
                            disabled={!isAuthed}
                            onClick={handleMenuOpenClick}
                            className={classes.iconButton}
                            aria-label="menu">
                            <MenuIcon/>
                        </IconButton>
                        <InputBase
                            disabled={!isAuthed}
                            className={classes.input}
                            placeholder={getMenuPlaceholder()}
                            inputProps={{
                                'aria-label': 'search lemon',
                                value: searchQuery,
                                onChange: handleChange,
                            }}
                        />
                        {
                            getSearchState() === 'isLemon' ?
                                <IconButton
                                    disabled={!isAuthed}
                                    onClick={() => {
                                        if (searchQuery.trim().length > 0) {
                                            handleSearch(searchQuery.trim());
                                        }
                                    }}
                                    type="button" className={classes.iconButton} aria-label="search">
                                    <SearchIcon/>
                                </IconButton> :
                                <IconButton
                                    disabled={!isAuthed}
                                    onClick={handleCustom}
                                    type="button" className={classes.iconButton} aria-label="search">
                                    <AddCircleIcon/>
                                </IconButton>
                        }
                    </Paper>
                    <Menu
                        id="search-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {
                            menuOptions.map((option, index) => (
                                <MenuItem
                                    key={option}
                                    selected={index === selectedIndex}
                                    onClick={(event) => handleMenuItemClick(event, index)}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                    </Menu>
                </Grid>
            </Grid>

            <div className={classes.chipsWrapper}>
                <div style={{
                    marginTop: '12px',
                }}>{searchResults ? searchResults.length : 0} Game(s) Found
                </div>
                {
                    searchResults && searchResults.map((g, i) => {
                        return <Chip
                            tabIndex={0}
                            onKeyPress={(e) => {
                                if (e.code.toLowerCase() === 'enter') {
                                    handleChipClick(g.gameTitle);
                                }
                            }}
                            key={i}
                            className={classes.chips}
                            color="primary"
                            icon={<AddIcon/>}
                            label={g.gameTitle}
                            onClick={() => {
                                handleChipClick(g.gameTitle);
                            }
                            }
                        />
                    })
                }
            </div>
            <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
                {errorMessage}
            </Typography>
            <h3>Picked Games {`${games && games.length}/${MAX_GAMES}`}</h3>
            {
                games && games.length ?
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.rows}>Title</TableCell>
                                <TableCell className={classes.rows}>Use Cover</TableCell>
                                <TableCell className={classes.rows}>Game Type</TableCell>
                                <TableCell className={classes.rows}>Upload Cover</TableCell>
                                <TableCell className={classes.rows}>Choose Cover</TableCell>
                                <TableCell className={classes.rows}>Enable Pick</TableCell>
                                <TableCell className={classes.rows}>Remove Game</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {

                                games.map((row, i) => {
                                    return <TableRow key={i} className={classes.rows}>
                                        <TableCell className={classes.rows} align="left">{row.gameTitle}</TableCell>
                                        <TableCell className={classes.rows} align="left">
                                            <Checkbox
                                                disabled={!isAuthed}
                                                checked={row.useCover}
                                                onChange={(e) => {
                                                    handleUseCoversChange(e.target.checked, row, i);
                                                }}
                                                name="useCoverCheckbox"
                                            />
                                        </TableCell>

                                        <TableCell className={classes.rows} align="left">
                                            <FormControl disabled={!isAuthed} sx={{m: 1, minWidth: 80}}>
                                                <Select
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={row.type}
                                                    onChange={(e) => {
                                                        const newGames = [...games];
                                                        newGames[i].type = e.target.value;
                                                        setGames(newGames);
                                                    }}
                                                    autoWidth
                                                    label="Age"

                                                >
                                                    <MenuItem value="lemon">Lemon</MenuItem>
                                                    <MenuItem value="custom">Custom</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>


                                        <TableCell className={classes.rows} align="left">
                                            {
                                                isAuthed ?
                                                    <>
                                                        <Button variant="outlined" color="secondary" onClick={(e) => {
                                                            uploadPopClick(e, i)
                                                        }}>
                                                            Upload
                                                        </Button>
                                                        <Popover
                                                            open={openUploadIndex === i}
                                                            anchorEl={uploadAnchor}
                                                            onClose={handleUploadPopClose}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                        >
                                                            <div style={{
                                                                padding: '10px',
                                                            }}>
                                                                <FileUploader setFileUrl={(url) => {
                                                                    const newStreamGames = [...games];
                                                                    newStreamGames[i].coverImageUrl = url;
                                                                    newStreamGames[i].useCover = true;
                                                                    newStreamGames[i].type = 'custom';
                                                                    setGames(newStreamGames);
                                                                }}/>
                                                            </div>
                                                        </Popover>
                                                    </> : null
                                            }
                                        </TableCell>
                                        <TableCell className={classes.rows}>
                                            <>
                                                <Button variant="outlined" color="secondary" onClick={(e) => {
                                                    chooseCoverPopClick(e, i)
                                                }}>
                                                    {
                                                        getCoverImg(row)
                                                    }
                                                </Button>
                                                <Popover
                                                    open={(
                                                            row.covers &&
                                                            row.covers.length &&
                                                            openChooseCoverIndex === i)
                                                        || false}
                                                    anchorEl={chooseCoverAnchor}
                                                    onClose={handleChooseCoverClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <div style={{
                                                        display: 'flex',
                                                        gap: '20px',
                                                        padding: '10px',
                                                    }}>
                                                        {getAllCovers(i, row)}
                                                    </div>
                                                </Popover>
                                            </>
                                        </TableCell>
                                        <TableCell className={classes.rows} align="left">
                                            <Checkbox
                                                disabled={!isAuthed}
                                                checked={row.enablePick === false? false : true}
                                                onChange={(e) => {
                                                    handleEnablePickChange(e.target.checked, row, i);
                                                }}
                                                name="useCoverCheckbox"
                                            />
                                        </TableCell>
                                        <TableCell
                                            onKeyPress={(e) => {
                                                if (!isAuthed) {
                                                    return;
                                                }
                                                if (e.code.toLowerCase() === "enter") {
                                                    removeGameClick(row.gameTitle, row.gameId);
                                                }
                                            }}
                                            className={classes.rows} align="left">
                                            <HighlightOffOutlinedIcon
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                tabIndex={0}
                                                onClick={() => {
                                                    if (!isAuthed) {
                                                        return;
                                                    }
                                                    removeGameClick(row.gameTitle, row.gameId);
                                                }}/>
                                        </TableCell>

                                    </TableRow>
                                })

                            }
                        </TableBody>
                    </Table> : <div>No Games Picked</div>
            }

            {/*<Grid item xs={12}>*/}
            {/*  {*/}
            {/*    coverUrls.map((image, i) => {*/}
            {/*      return image && <a*/}
            {/*        key={i}*/}
            {/*        href={image.src} target="_blank" rel="noreferrer">*/}
            {/*        <img*/}
            {/*          className={classes.coverImg}*/}
            {/*          src={image.src}*/}
            {/*          alt="game cover"*/}
            {/*        />*/}
            {/*      </a>*/}
            {/*      */}
            {/*    })*/}
            {/*  }*/}
            {/*</Grid>*/}
        </div>

    );
}
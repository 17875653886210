import React, {useEffect, useState} from 'react';
import {Link as ReactLink} from "react-router-dom";

import {makeStyles} from '@material-ui/core/styles';
import Title from './Title';
import FormattedDate from "./FormattedDate";
import Description from "./Description";
import {insertLineBreaks, safeVar} from "../../utils";
import {Grid, Typography} from "@material-ui/core";
import GameCardItem from "../games/GameCardItem";


const useStyles = makeStyles((theme) => ({
    title: {},
    h3: {
        margin: 0,
    }

}));

export default function UpcomingStream({data}) {
    const classes = useStyles();
    console.log("UpcomingStream::component", data);
    return (
        <React.Fragment>
            <Title>Upcoming Stream</Title>
            <Grid xs={12} item style={{
                display: 'flex',
                gap: 100,
            }}>
                <Grid item xs={8}>
                    <ReactLink to={`/view-stream/${data._id}`}>
                        <Typography component="h4" variant="h4" color="primary">
                            {safeVar(data, 'streamTitle', 'Stream Title')}
                        </Typography>
                    </ReactLink>
                    <FormattedDate component="h6" variant="h6">
                        {safeVar(data, 'streamDate')}
                    </FormattedDate>
                    <Description component="p" variant="body1">
                        {insertLineBreaks(
                            safeVar(data, 'streamDescription', 'Description')
                        )}
                    </Description>
                </Grid>
                <Grid item xs={6}>
                    {
                        data && data.streamGames ? <Grid item xs={6}>
                            <GameCardItem
                                game={data.streamGames[0]}/></Grid>: null
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
import React from 'react';

/**
 *
 * @type {React.Context<{user: null}>}
 */
export const UserContext = React.createContext({
    user: null,
    role: "anonymous",
    isAdmin: false,
    renderIfAdmin: null,
});
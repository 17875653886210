import React, {useEffect, useState} from "react";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import FileUploader from "./FileUploader";
import {makeStyles} from "@material-ui/core/styles";
import {postFile} from "./api";
import {Button, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        marginBottom: '64px'
    },
    formItem: {
        width: '100%',
        marginBottom: '20px',
        backgroundColor: '#d3e2ff',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        backgroundColor: '#b0cdff',
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    readOnly: {
        width: 500,
    },
    buttonField: {
        backgroundColor: '#d3e2ff',
        padding: theme.spacing(2),
        display: 'flex',
    }
}));

const SoundUploader = ({initialVal, prefix, setFileUrl}) => {

    const classes = useStyles();
    const [isError, setIsError] = useState(false);
    const [selected, setSelected] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fileName, setFileName] = useState(initialVal);

    useEffect(() => {
        console.log("SoundUploader::initialVal=", initialVal);
        setFileName(initialVal);
    }, [initialVal])

    const submitFile = () => {
        console.log("Selected File=", selected[0]);
        postFile(selected[0])
            .then((res) => {
                if (res && res.data) {
                    console.log('File Upload success', res.data);
                    setFileUrl(process.env.REACT_APP_SERVER_URL + res.data.url);
                    setFileName(process.env.REACT_APP_SERVER_URL + res.data.url);
                    setSelected(null);
                }
            })
            .catch((err) => {
                console.log('err:', err);
                setIsError(true);
                setErrorMessage(err);
            });
    };

    return (
        <>
            <Typography variant="subtitle2" className={`upload-message`}>
                {fileName}
            </Typography>
            <Grid container item xs={3}>
                <Paper variant="outlined" className={classes.buttonField}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    </div>
                    <TextField
                        style={{
                            marginRight: 20
                        }}
                        className={classes.readOnly}
                        id="standard-read-only-input"
                        label="Filename"
                        value={selected && selected.length > 0 ? selected[0].name : ''}
                        InputProps={{
                            readOnly: false,
                        }}
                    />

                    <label htmlFor={`${prefix}-btn-upload`}>
                        <input
                            id={`${prefix}-btn-upload`}
                            name={`${prefix}-btn-upload`}
                            style={{display: 'none'}}
                            type="file"
                            accept=".mp3,audio/*"
                            onChange={(e) => {
                                console.log('e.target.files', e.target.files);
                                setSelected(e.target.files);
                            }}/>
                        <Button
                            style={{
                                width: 125,
                                height: '100%',
                                marginRight: 10
                            }}
                            className="btn-choose"
                            variant="outlined"
                            color="secondary"
                            component="span"
                        >
                            Choose File
                        </Button>
                    </label>

                    <Button
                        className={`${prefix}-btn-upload`}
                        color="primary"
                        variant="contained"
                        component="span"
                        disabled={!(selected && selected.length > 0 && selected[0].name)}
                        onClick={submitFile}>
                        Submit
                    </Button>
                </Paper>
                <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
                    {errorMessage}
                </Typography>
            </Grid>
        </>

    )
}

export default SoundUploader;
import axios from 'axios';

const headers = {
  'Content-Type': 'application/json'
}

export async function searchGame(name) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  let encodedName = encodeURIComponent(name);
  let endpoint = `${serverUrl}/api/games/lemon-search-2/${encodedName}`;
  return axios.get(endpoint, {headers});
}

export function getLemonCoverImage(gameId) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const endpoint = `${serverUrl}/api/games/lemon-cover-img`;
  let payload = {
    gameId,
  }
  return axios.post(endpoint, payload, {headers});
}

export function addStream(data) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const endpoint = `${serverUrl}/api/streams/add`;
  let payload = {
    ...data
  }
  return axios.post(endpoint, payload, {headers});
}

export function getStreams() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const endpoint = `${serverUrl}/api/streams`;
  return axios.get(endpoint, {headers});
}

export function getStream(id) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  let endpoint = `${serverUrl}/api/streams/${id}`;
  return axios.get(endpoint, {headers});
}

export function deleteStream(id) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  let endpoint = `${serverUrl}/api/streams/delete`;
  let payload = {
    id,
  }
  return axios.post(endpoint, payload, {headers});
}

export function editStream(id, data) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  let endpoint = `${serverUrl}/api/streams/edit`;
  let payload = {
    id,
    ...data,
  };
  return axios.post(endpoint, payload, {headers});
}
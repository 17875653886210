import React, {useEffect, useState} from 'react';
// import clsx from 'clsx';

import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {Divider} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Title from "./Title";
import {hexToDec} from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  memoryInput: {
    marginBottom: '20px',
  },
  paper: {
    backgroundColor: '#b0cdff',
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  buttonRow: {
    marginBottom: '16px',
  },
  divider: {
    marginBottom: '20px',
  },
  memoryMapContainer: {
    border: '1px solid grey',
    width: '100%',
    padding: '20px',
    height: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflowX: 'scroll'
  },
  memoryChunk: {
    fontSize: '10px',
    color: 'black',
  }
}));

const MemoryMapper = () => {
  const classes = useStyles();
  
  
  const [segmentsInput, setSegmentsInput] = useState('Default Value');
  const [defaultMap, setDefaultMap] = useState([
    {
      min: hexToDec('0000'),
      max: hexToDec('00ff'),
      name: '',
      title: 'Zeropage addressing',
      colour: '#ffde13'
    },
    {
      min: hexToDec('0100'),
      max: hexToDec('01ff'),
      name: '',
      title: 'Enhanced Zeropage (inc. stack)',
      colour: '#ff8700'
    },
    {
      min: hexToDec('0200'),
      max: hexToDec('03ff'),
      name: 'OS',
      title: 'OS and BASIC Pointers',
      colour: '#ffde13'
    },
    {
      min: hexToDec('0400'),
      max: hexToDec('07ff'),
      name: 'Screen',
      title: 'Screen RAM',
      colour: '#ff8700'
    },
    {
      min: hexToDec('0800'),
      max: hexToDec('9fff'),
      name: 'Free BASIC',
      title: 'Free BASIC program storage (38911 bytes)',
      colour: '#04da32'
    },
    {
      min: hexToDec('A000'),
      max: hexToDec('BFFF'),
      name: 'BASIC ROM',
      title: 'BASIC ROM (Available if switched out)',
      colour: '#fff445'
    },
    {
      min: hexToDec('C000'),
      max: hexToDec('CFFF'),
      name: 'Free',
      title: 'Free program storage',
      colour: '#04da32'
    },
    {
      min: hexToDec('D000'),
      max: hexToDec('dfff'),
      name: 'Reserved',
      title: 'Reserved for interface extensions',
      colour: '#ff8700'
    },
    {
      min: hexToDec('e000'),
      max: hexToDec('ffff'),
      name: 'KERNAL',
      title: 'KERNAL Rom (switchable)',
      colour: '#fff445'
    },
  ]);
  const [defaultCustom, setDefaultCustom] = useState([
    {
      min: hexToDec('0000'),
      max: hexToDec('00ff'),
      name: '',
      title: 'Zeropage addressing',
      colour: '#ffde13'
    },
    {
      min: hexToDec('0100'),
      max: hexToDec('01ff'),
      name: '',
      title: 'Enhanced Zeropage (inc. stack)',
      colour: '#ff8700'
    },
    {
      min: hexToDec('0200'),
      max: hexToDec('03ff'),
      name: 'OS',
      title: 'OS and BASIC Pointers',
      colour: '#ffde13'
    },
    {
      min: hexToDec('0400'),
      max: hexToDec('07ff'),
      name: 'Screen',
      title: 'Screen RAM',
      colour: '#ff8700'
    },
    {
      min: hexToDec('d000'),
      max: hexToDec('dfff'),
      name: 'Reserved',
      title: 'Reserved for interface extensions',
      colour: '#ff8700'
    },
  ]);
  const [customMap, setCustomMap]  = useState([])
  
  const memToWidth = (val) => {
    let mem = Math.max(Math.floor(val / 60), 2) - 1;
    return `${mem}px`;
  }
  
  const applyMemorySegments = () => {
    setSegmentsInput('');
  }
  
  useEffect(() => {
    
    // console.log('Input:', segmentsInput);
    
    const rows = segmentsInput.split('\n');
    const newSegments = [...defaultCustom];
    
    rows.forEach((rowStr, i) => {
      let val = rowStr.trim();
      if (val && val.length) {
        //$0801-$080d Basic
        //$080e-$1041 Basic End
        //$4000-$6fdf Map data
        //$c400-$c40e Main
        //$c000-$c3ff screen memory
        //$c40f-$c40e End main
        //$d000-$d9ff Sprites
        //$f000-$f7ff Game-Charset
        
        const name = val.substring(12, val.length);
        const bytesRange = val.match(/([$])\w+/g);
        
        // console.log('i %s', i, val, name, bytesRange);
        
        if (name && bytesRange && bytesRange.length && bytesRange.length === 2) {
          const min = bytesRange[0].replace('$', '');
          const max = bytesRange[1].replace('$', '');
          
          const minVal = parseInt(min, 16);
          const maxVal = parseInt(max, 16);
          
          if (maxVal - minVal >= 1) {
            console.log('i %s', i, name, min, max);
            const title = name;
            const data = {
              min: minVal,
              max: maxVal,
              name: title,
              title: title,
              colour: `#ff0000`,
            };
            // console.log(i, data);
            newSegments.push(data);
          }
        }

      }

    });
    
    
    setCustomMap(newSegments);
    
  }, [segmentsInput, defaultCustom]);
  
  return <Container maxWidth="lg" className={classes.container}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>
            C64 Memory Mapper
          </Title>
          
          <a href="https://www.c64-wiki.com/wiki/Memory_Map" target="_blank" rel="noopener noreferrer"
             aria-label="C64 Wiki.com">
            Memory Map (C64 Wiki)
          </a>
          <p>
            The diagrams below show the default C64 Memory map.  The lower map is a custom  map.  You can
            paste Default Segment from kick assembler output to show your own memory footprint.
          </p>
          
          <Divider className={classes.divider}/>
          
          <TextField
            className={classes.buttonRow}
            id="outlined-multiline-static"
            label="Default Segments"
            multiline
            minRows={10}
            variant="outlined"
            inputProps={{
              'aria-label': 'enter-default-segments',
              value: segmentsInput,
              onChange: (e) => {
                setSegmentsInput(e.target.value);
              },
            }}
          />
          
          
          <Grid item xs={4} className={classes.buttonRow}>
            <Button variant="contained" color="primary" onClick={applyMemorySegments}>
              Clear
            </Button>
          
          </Grid>
          
          
          <Grid item xs={12}>
            <Title>
              C64 Memory Map:
            </Title>
            <Divider/>
            
            <div className={classes.memoryMapContainer}>
              
              <h3>
                Default C64 Memory MAP
              </h3>
              <div className="memory-mapper" style={{
                // padding: '20px',
                border: '1px solid black',
                height: '100px',
                width: memToWidth(65535),
                position: 'relative',
                marginBottom: '24px',
              }}>
                {
                  defaultMap.map((area, i) => {
                    
                    return <div
                      className={classes.memoryChunk}
                      key={`${i}_${new Date()}`}
                      title={area.title}
                      style={{
                        backgroundColor: area.colour,
                        top: '0',
                        left: memToWidth(area.min),
                        width: memToWidth(area.max - area.min),
                        height: '100%',
                        position: 'absolute',
                      }}>
                      {area.name}
                    </div>
                  })
                }
              </div>
  
              <h3>
                Custom Memory MAP
              </h3>
              <div className="memory-mapper" style={{
                // padding: '20px',
                backgroundColor: '#04da32',
                border: '1px solid black',
                height: '100px',
                width: memToWidth(65535),
                position: 'relative',
                marginBottom: '24px',
              }}>
                {
                  customMap.map((area, i) => {
        
                    return <div
                      className={classes.memoryChunk}
                      key={`${i}_${new Date()}`}
                      title={area.title}
                      style={{
                        overflowWrap: 'break-word',
                        backgroundColor: area.colour,
                        top: '0',
                        left: memToWidth(area.min),
                        width: memToWidth(area.max - area.min),
                        height: '100%',
                        position: 'absolute',
                      }}>
                      {area.name}
                    </div>
                  })
                }
              </div>
              
            </div>

          
          </Grid>
        
        
        </Paper>
      </Grid>
    </Grid>
  </Container>
  
}

export default MemoryMapper;
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    makeStyles,
    Typography
} from "@material-ui/core";
import {Link as ReactLink, useHistory} from "react-router-dom";

import React, {useContext} from "react";
import {UserContext} from "../../auth/UserContext";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 345,
        minWidth: 345,
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    footer: {
        alignItems: 'flex-end',
    }
}));

const CollectionItem = ({id, author, title, description, imageUrl}) => {
    const classes = useStyles();
    const history = useHistory();
    const {user, role} = useContext(UserContext);

    const canEdit = () => {
        if (!user) {
            return false;
        }
        if (!user.login) {
            return false;
        }
        return author === user.login;
    }

    return (
        <Card className={classes.root}>
            {/*<ReactLink to={`/view-collection/${id}`}>*/}
                <CardActionArea
                    onClick={() => {
                        console.log("action");
                        history.push(`/view-collection/${id}`);
                    }}>
                    <CardMedia
                        height="140"
                        className={classes.media}
                        image={imageUrl}
                        title="Collection Cover Art"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {title}
                        </Typography>
                        <Typography gutterBottom variant="h6" component="h6">
                            by {author}
                        </Typography>
                        <Typography style={{
                            height: '60px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                        }} variant="body2" color="textSecondary" component="p">
                            {description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            {/*</ReactLink>*/}

            <CardActions className={classes.footer}>
                {/*<Button size="small" color="primary">*/}
                {/*    Share*/}
                {/*</Button>*/}
                {
                    canEdit()?
                      <ReactLink to={`/collection/${id}`}>
                        <Button size="small" color="primary">
                            Edit
                        </Button>
                    </ReactLink> : null


                }
                {/*<ReactLink to={`/collection/view/${id}`}>*/}
                {/*    <Button size="small" color="primary">*/}
                {/*        View*/}
                {/*    </Button>*/}
                {/*</ReactLink>*/}
            </CardActions>
        </Card>
    )
}

export default CollectionItem;
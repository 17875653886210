import React, {useContext, useEffect, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';

import {Snackbar} from "@material-ui/core";

import Alert from '@material-ui/lab/Alert';
import AddCollectionFormPage from "./AddCollectionFormPage";
import {addCollection, editCollection, getCollection, deleteCollection} from "./api";
import {UserContext} from "../../auth/UserContext";


const AddCollectionFormContainer = ({isEditMode}) => {
    const {id} = useParams();
    const history = useHistory();
    const {user, role} = useContext(UserContext);
    const [data, setData] = useState(null);
    const [shouldShowSuccess, setShouldShowSuccess] = useState(false);
    const [hasAdded, setHasAdded] = useState(false);
    // const [canEdit, setCanEdit] = useState(false);

    const handleClose = () => {
        setShouldShowSuccess(false);
    }

    const fetchData = async (id) => {
        console.log('AddCollectionFormContainer::fetchData');
        try {
            let res = await getCollection(id);
            return res.data;
        } catch (e) {
            console.log(`Error: ${e}`);
        }
    }

    useEffect(() => {
        console.log("AddCollectionFormContainer", id, isEditMode);

        if (isEditMode && id && user) {
            fetchData(id).then(data => {

                if (data && data.collection) {
                    if (data.collection.author !== user.login) {
                        // setCanEdit(false);
                        return;
                    }
                    // setCanEdit(true);
                    setData(data.collection);
                    console.log('AddCollectionFormContainer::setData=', data.collection);
                }
            });
        }
    }, [id, isEditMode, user]);

    /**
     * @todo Don't allow addStream to be called once
     * @param data
     * @returns {Promise}
     */
    const save = async (data) => {
        console.log('AddCollectionForm::save', data);

        const dataWithUser = {
            ...data,
            author: user.login,
        };

        if (isEditMode || hasAdded) {
            return editCollection(id, dataWithUser).then((res) => {
                setShouldShowSuccess(true);
            });
        } else {
            try {
                let res = await addCollection(dataWithUser);
                if (res.data && res.data.collection) {
                    setHasAdded(true);
                    setShouldShowSuccess(true);

                    history.push(`/collection/${res.data.collection._id}`);
                    return;
                }
                console.log("AddCollectionFormContainer unusual response when attempting to add collection", res);
            } catch (e) {
                console.log("AddCollectionFormContainer error adding collection", e);
            }
            return addCollection(dataWithUser);
        }
    };

    const doDelete = async ({id}) => {
        if (isEditMode) {
            try {
                const res = await deleteCollection(id);
                console.log("Delete Successful", res.data);
                history.push('/view-collections')
            } catch (e) {
                console.log("Unable to delete");
                console.log(e);
            }
        }
    }

    return (
        <div>
            <AddCollectionFormPage
                onDelete={doDelete}
                onSave={save}
                isEditMode={isEditMode}
                data={data}
            />
            <Snackbar open={shouldShowSuccess} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Collection saved successfully!
                </Alert>
            </Snackbar>
        </div>
    )
}

export default AddCollectionFormContainer;
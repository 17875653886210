import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import AddStreamFormPage from './AddStreamFormPage';
import {getStream, addStream, editStream} from './api';
import {Snackbar} from "@material-ui/core";

import Alert from '@material-ui/lab/Alert';


const AddStreamFormContainer = ({isEditMode}) => {

    const {id} = useParams();
    const [streamData, setStreamData] = useState(null);
    const [shouldShowSuccess, setShouldShowSuccess] = useState(false);
    const [hasAdded, setHasAdded] = useState(false);
    
    const handleClose = () => {
        setShouldShowSuccess(false);
    }

    useEffect(() => {
        console.log("AddStreamFormContainer", id, isEditMode);
        if (isEditMode && id) {
            getStream(id).then((res) => {
                setStreamData(res.data.stream);
                console.log('res stream:', res.data);
            });
        }
    }, [id, isEditMode]);

    /**
     * @todo Don't allow addStream to be called once
     * @param data
     * @returns {Promise}
     */
    const saveStream = (data) => {
        if (isEditMode || hasAdded) {
            return editStream(id, data).then((res) => {
                setShouldShowSuccess(true);
                console.log('AddStreamFormContainer :: editStream', res);
            });
        } else {
            return addStream(data).then((res) => {
                console.log('AddStreamFormContainer :: addStream', res);
                setHasAdded(true);
                setShouldShowSuccess(true);
            });
        }
    };

    return (
        <div>
            <AddStreamFormPage
                onSave={saveStream}

                isEditMode={isEditMode}
                data={streamData}
            />
            <Snackbar open={shouldShowSuccess} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Stream saved successfully!
                </Alert>
            </Snackbar>
        </div>
    )
}

export default AddStreamFormContainer;
import axios from 'axios';

const headers = {
  'Content-Type': 'application/json'
}

// api/games/all-sfx
export function getAllSfx() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const endpoint = `${serverUrl}/api/games/all-sfx`;
  return axios.get(endpoint, {headers});
}


import React, {useContext, useEffect, useState} from 'react';
import {Link as ReactLink, Link as RLink} from 'react-router-dom';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/AddCircleOutlined';

import Title from './Title';
import {getStreams} from "../streams/api";
import {getSummary, removeFutureStreams, sortByDate} from "../streams/utils";
import {UserContext} from "../../auth/UserContext";

const useStyles = makeStyles((theme) => ({
  title: {
   
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function StreamsSummary() {

  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const { isAdmin } = useContext(UserContext);

  useEffect(() => {
    getStreams().then((res) => {
      const streams = [...res.data.streams];
      const filtered = removeFutureStreams(streams);
      const sorted = sortByDate(filtered, false);
      setRows(sorted.slice(0, 5));
    });
  }, []);

  return (
    <React.Fragment>
      <Title className={classes.title}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
        }} 
          >
          Recent Streams
          {isAdmin? <RLink to='/add-stream'>
            <AddIcon />
          </RLink> : null }
        </div>
      </Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Games</TableCell>
            <TableCell>{}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
              /**
               *  id: ID!
               streamTitle: String
               streamDescription: String
               streamDate: String
               streamGames: [Game]
               */
              <TableRow key={i}>
                <TableCell>{
                  moment(row.streamDate).format('DD MMM YYYY; hh:mm')
                }</TableCell>
                <TableCell>
                  <ReactLink to={`/stream/${row._id}`}>
                    {row.streamTitle}
                  </ReactLink>
                </TableCell>
                <TableCell>{getSummary(row.streamDescription)}</TableCell>
                <TableCell>{row.streamGames.length}</TableCell>
                <TableCell></TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <RLink color="primary" to={'/streams'}>
          See more streams
        </RLink>
      </div>
    </React.Fragment>
  );
}
import axios from "axios";

export function postCoverImage(file) {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    let headers = {
        'Content-Type': 'multipart/form-data',
        onUploadProgress: progressEvent => console.log(progressEvent.loaded)
    };
    let endpoint = `${serverUrl}/api/images-upload/cover`;
    const formData = new FormData();
    console.log('submitCoverImage ', file);
    // formData.append('name', name);
    formData.append('cover', file);
    return axios.post(endpoint, formData, { headers } );
}

export function postFile(file) {
    ///api/uploads/single-file
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    let headers = {
        'Content-Type': 'multipart/form-data',
        onUploadProgress: progressEvent => console.log(progressEvent.loaded)
    };
    let endpoint = `${serverUrl}/api/uploads/single-file`;
    const formData = new FormData();
    console.log('submit file ', file);
    // formData.append('name', name);
    formData.append('my-file', file);
    // formData.append('extension', extension);
    return axios.post(endpoint, formData, { headers } );
}

 import React from 'react';
import {
  BrowserRouter as Router,
} from "react-router-dom";

import './App.css';
import MainApp from './app/MainApp';
 import {Helmet} from "react-helmet";

function App() {
  return (
    <div className="App">
    <Helmet>
        {`<!-- Primary Meta Tags -->`}
        <title>ChilliOS @hayesmaker64.com</title>
        <meta name="title" content="ChilliOS - hayesmaker64.com" />
        <meta name="description" content="With Chilli-OS you can view past hayesmaker64 streams, browse and create vido game collections, listen to awesome SID Tune Mix tapes and more!" />

        {`<!-- Open Graph / Facebook -->`}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://hayesmaker64.com/chillios/" />
        <meta property="og:title" content="ChilliOS @hayesmaker64.com" />
        <meta property="og:description" content="With Chilli-OS you can view past hayesmaker64 streams, browse and create vido game collections, listen to awesome SID Tune Mix tapes and more!" />
        <meta property="og:image" content="https://hayesmaker64.com/v1/uploads/my-file-1690389245591-522617435.PNG" />

        {`<!-- Twitter -->`}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="http://hayesmaker64.com/chillios/" />
        <meta property="twitter:title" content="ChilliOS @hayesmaker64.com" />
        <meta property="twitter:description" content="With Chilli-OS you can view past hayesmaker64 streams, browse and create vido game collections, listen to awesome SID Tune Mix tapes and more!" />
        <meta property="twitter:image" content="https://hayesmaker64.com/v1/uploads/my-file-1690389245591-522617435.PNG" />
    </Helmet>
      <Router basename="/chillios">
        <MainApp />
      </Router>
    </div>
  );
}

export default App;

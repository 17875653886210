import React, { useState, useEffect } from 'react';
import {
  useLocation,
  useHistory,
} from "react-router-dom";

import {getUser, authoriseUser} from '../../auth/api';
import { UserContext } from '../../auth/UserContext';
import Layout from './Layout';
import axios from "axios";

export default function MainApp() {

  console.log('NODE_ENV', process.env.NODE_ENV);
  console.log('REACT_APP_GAMEPICKER_URL', process.env.REACT_APP_GAMEPICKER_URL);

  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const userLogin = async (token) => {

      console.log("UserLogin token=", token);
      console.log("UserLogin token=", sessionStorage.twitchOAuthToken);

      try {

        let userResponse = await getUser(token);
        const resUser = userResponse.data && userResponse.data.data && userResponse.data.data[0];

        console.log("ResUser=", resUser);

        if (resUser) {
            setUser(resUser);

            console.log('location.pathname' + location.pathname);
            //
            // @todo save previous location and restore to url if logging in from another route?
            //
            history.push(location.pathname);

          let authorisedUser = await authoriseUser(resUser.id, token);

          if (authorisedUser && authorisedUser.data && authorisedUser.data.authorised) {

            if (authorisedUser.data.authorised === 'ok') {
              setRole(authorisedUser.data.role);
            }

            if (authorisedUser.data.role === 'superadmin') {
              setIsAdmin(true);
            }

            console.log('Authorised User=', authorisedUser.data.user);

          }

        } else {

          console.log('Error finding user in response');
          console.log(userResponse);

        }

      } catch (e) {

        console.log('There was an error logging in');
        console.log(e);

      }
    };

    const hasMatch = (hash, expr) => {
      const match = hash.match(expr);
      return match ? match[1] : true;
    };
    const accessTokenMatcher = /access_token=(\w+)/;
    const hasAccessToken = location.hash.match(accessTokenMatcher);

    if (hasAccessToken) {
      sessionStorage.twitchOAuthToken = hasMatch(
        location.hash,
        accessTokenMatcher
      );
      console.log('hasAccessToken', sessionStorage.twitchOAuthToken);
      userLogin(sessionStorage.twitchOAuthToken);
    } else {
      console.log('login :: twitchOAuthToken', sessionStorage.twitchOAuthToken);
      if (sessionStorage.getItem('twitchOAuthToken') !== null) {
        console.log('logging in:', typeof sessionStorage.twitchOAuthToken);
        userLogin(sessionStorage.twitchOAuthToken);
      }
    }
  }, [history]);

  const renderIfAdmin = (node) => {
    return isAdmin? node : null;
  }
  
  const logout = async () => {

    try {
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      const payload = {
        client_id: process.env.REACT_APP_TWITCHAPP_CLIENT_ID,
        token: sessionStorage.twitchOAuthToken
      }
      let res = await axios.post('https://id.twitch.tv/oauth2/revoke', payload, {headers});
      console.log('Session Logout confirmed', res.data);

      sessionStorage.clear();
      history.push(location.pathname);
      window.location.reload();
    } catch (e) {
      console.log("There was an error logging out");
      console.log(e);

      sessionStorage.clear();
      history.push(location.pathname);
      window.location.reload();
    }
  };

  return (
    <UserContext.Provider value={{ user, role, isAdmin, renderIfAdmin }}>
      <Layout logout={logout}  />
    </UserContext.Provider>
  )
};
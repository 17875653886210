import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Link from '@material-ui/core/Link';
// import clsx from 'clsx';
import 'date-fns';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { Divider, Button } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Title from '../dashboard/Title';
import { getStream } from './api';
import {insertLineBreaks, safeVar} from "../../utils";
import Description from "../dashboard/Description";
import FormattedDate from "../dashboard/FormattedDate";
import GameCardItem from "../games/GameCardItem";
import Typography from "@material-ui/core/Typography";



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    marginBottom: '64px'
  },
  formItem: {
    width: '100%',
    marginBottom: '20px',
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    minHeight: '90vh',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    backgroundColor: '#b0cdff',
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));


const StreamPage = () => {
  const classes = useStyles();
  const {id} = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    console.log('get stream id:', id);
    if (!id) {
      return;
    }

    getStream(id).then((res) => {
        console.log('res stream:', res.data);
        if (res && res.data && res.data.stream) {
          setData(res.data.stream);
        }
    });
  }, [id]);


  return (
    <Container maxWidth="lg" className={classes.container}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={3}>
          <Grid item xs={12}>

            <Paper className={classes.paper}>
              <Title>
                {safeVar(data, 'streamTitle', 'Stream Title')}
              </Title>
              <FormattedDate>
                {safeVar(data, 'streamDate')}
              </FormattedDate>
              <Description>
                {insertLineBreaks(
                    safeVar(data, 'streamDescription', 'Description')
                )}
              </Description>
              <Divider
                style={{
                  marginBottom: 24
                }}
              />
              <Typography
                  style={{
                    marginBottom: 12
                  }}
                  component="h5" variant="h6" color="primary" gutterBottom>
                Featured Games
              </Typography>
              <Grid container xs={12} item style={{
                marginBottom: 24,
                display: 'flex',
                gap: '22px',
              }}>
                {
                    data && data.streamGames && data.streamGames.map((game, i) => {
                      return <GameCardItem key={i} game={game}/>
                    })
                }
              </Grid>

              {
                data && data.vods && data.vods.length?
                    (
                        <>
                          <Divider
                              style={{
                                marginBottom: 24
                              }}
                          />
                          <Typography
                              style={{
                                marginBottom: 12
                              }}
                              component="h5" variant="h6" color="primary" gutterBottom>
                            Watch the Stream
                          </Typography>
                          <div
                            style={{
                            width: '100%',
                            marginBottom: 12,
                            display: "flex",
                            flexDirection: "column"
                            }}>
                          {
                            data.vods.map((vodUrl, i) => {
                              //https://www.twitch.tv/videos/1882311423
                              const vodId = vodUrl.split("/")[vodUrl.split("/").length - 1];
                              console.log('vodId', vodId);
                              const src =
                                  `https://player.twitch.tv/?video=${vodId}&parent=localhost&parent=hayesmaker64.com&autoplay=false`;
                              return (
                                  <div
                                      style={{
                                        marginBottom: 12,
                                      }}
                                      key={i}>
                                    <iframe
                                        src={src}
                                        width="800"
                                        height="600"
                                        allowFullScreen>
                                    </iframe>
                                    <div>
                                      <Link color="primary" href={vodUrl} target="_blank" rel="noopener noreferrer" >
                                        <Button
                                            variant="contained"
                                            color="secondary">
                                          Watch on Twitch
                                        </Button>
                                      </Link>
                                    </div>

                                  </div>
                              )
                            })
                          }
                          </div>
                        </>
                    ) : null
              }
              <Divider
                  style={{
                    marginBottom: 24
                  }}
              />
              <Paper style={{
                width: '400px',
                display: 'flex',
                padding: 20,
                // justifyContent: 'center',
                // alignItems: 'center',
                backgroundColor: 'transparent'
              }} variant="outlined">
                <Typography color="primary" coponent="h3" variant="h4">
                  Thanks for watching!
                </Typography>
              </Paper>

            </Paper>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </Container>
  )
}

export default StreamPage;
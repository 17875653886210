import React, {useContext, useEffect, useState} from 'react';
// import clsx from 'clsx';
import 'date-fns';

import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import {Divider, Button} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Title from '../dashboard/Title';
import GameSelector from '../formSubcomponents/GameSelector';
import {UserContext} from "../../auth/UserContext";
import MultiValueForm from "../formSubcomponents/MultiValueForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    marginBottom: '64px'
  },
  formItem: {
    width: '100%',
    marginBottom: '20px',
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    minHeight: '90vh',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    backgroundColor: '#b0cdff',
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const AddStreamFormPage = ({isEditMode, data, onSave}) => {
  const [streamTitle, setStreamTitle] = useState('');
  const [streamDescription, setStreamDescription] = useState('');
  const [isCurrent, setIsCurrent] = useState(false);
  const [streamDate, setStreamDate] = React.useState(new Date());
  const [streamGames, setStreamGames] = useState([]);
  const {renderIfAdmin, isAdmin} = useContext(UserContext);
  const [vods, setVods] = useState(null);
  
  const classes = useStyles();
  
  const onSaveClick = () => {
    onSave.call(this,
      {
        streamTitle,
        isCurrent,
        streamDescription,
        streamDate,
        streamGames,
        vods,
      }
    );
  };
  
  useEffect(() => {
    if (!data) {
      return;
    }
    if (isEditMode) {
      setStreamTitle(data.streamTitle);
      setStreamDescription(data.streamDescription);
      setIsCurrent(data.isCurrent || false);
      setStreamDate(data.streamDate);
      setStreamGames(data.streamGames);

      console.log('SetVods::data.vods=', data.vods);
      setVods(data.vods);
    }
  }, [data, isEditMode]);
  
  const handleIsCurrentChange = (event) => {
    setIsCurrent(event.target.checked);
  };
  
  return (
    <Container className={classes.container}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={3}>
          <Grid item={true} xs={12}>
            
            <Paper className={classes.paper}>
              <Title>
                {isEditMode ? "Edit Stream" : "Add New Stream"}
              </Title>
              <Divider/>
              <form className={classes.root} noValidate autoComplete="off">
                <Grid item={true} xs={12}>
                  <Grid item={true} xs={12}>
                    <h3>Details</h3>
                    <TextField
                      disabled={!isAdmin}
                      className={classes.formItem}
                      label="Stream Title"
                      value={streamTitle}
                      onChange={
                        (e) => {
                          setStreamTitle(e.target.value);
                        }
                      }
                      variant="outlined"
                    />
                    <FormGroup
                      style={{
                        marginBottom: '20px'
                      }}
                      row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!isAdmin}
                            checked={isCurrent}
                            onChange={handleIsCurrentChange}
                            name="isCurrent"
                          />}
                        label="Is Current Stream"
                      />
                    </FormGroup>
                    <TextField
                      disabled={!isAdmin}
                      className={classes.formItem}
                      label="Stream Description"
                      multiline
                      minRows={5}
                      value={streamDescription}
                      onChange={
                        (e) => {
                          setStreamDescription(e.target.value);
                        }
                      }
                      variant="outlined"
                    />
                    <Grid item xs={6}>
                      <KeyboardDatePicker
                        disabled={!isAdmin}
                        className={classes.formItem}
                        margin="normal"
                        id="date-picker-dialog"
                        label="Date of Stream"
                        format="MM/dd/yyyy"
                        value={streamDate}
                        onChange={setStreamDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <KeyboardTimePicker
                        disabled={!isAdmin}
                        className={classes.formItem}
                        margin="normal"
                        id="time-picker"
                        label="Time of Stream"
                        value={streamDate}
                        onChange={setStreamDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <GameSelector
                      isAuthed={isAdmin}
                      games={streamGames}
                      setGames={setStreamGames}
                    />
                    <Grid item xs={4}>
                      <h3>Vod Links</h3>
                      <MultiValueForm
                          defaultLabel={'Vod URL'}
                          initialValues={vods}
                          setResults={setVods}
                      />
                    </Grid>

                  </Grid>
                </Grid>
                
                <Divider style={{
                  marginBottom: '64px'
                }}/>
                <Grid item xs={4}>
                  {
                    renderIfAdmin(
                      <Button style={{
                        width: '100%'
                        }}
                        variant="contained"
                        color="primary"
                        onClick={onSaveClick}
                        >
                        {"Save"}
                      </Button>
                    )
                  }
                
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </Container>
  )
}

export default AddStreamFormPage;
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Button, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import {getCollection} from "./api";
import GameCardItem from "../games/GameCardItem";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        marginBottom: '64px'
    },
    formItem: {
        width: '100%',
        marginBottom: '20px',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        maxWidth: '100%',
        minHeight: '90vh',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        backgroundColor: '#b0cdff',
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    secondaryButton: {},
    readOnly: {
        width: 500,
    },
    buttonField: {
        backgroundColor: '#d3e2ff',
        padding: theme.spacing(2),
        display: 'flex',
    }
}));
const ViewCollectionPage = ({}) => {
    const classes = useStyles();

    const {id} = useParams();

    const [data, setData] = useState(null);

    const fetchData = async (id) => {
        try {
            let res = await getCollection(id);
            console.log('ViewCollectionPage::fetchData', res);
            return res.data;
        } catch (e) {
            console.log(`Error: ${e}`);
        }
    }

    useEffect(() => {
        fetchData(id).then(data => {
            console.log("setData", data);
            setData(data.collection);
        });
    }, [id])

    return (
        <Container className={classes.container}>
            <Grid container spacing={3}>
                <Grid item={true} xs={12}>

                    <Paper className={classes.paper}>
                        <Typography variant="h4">
                            {data && data.title}
                        </Typography>
                        <Typography variant="h5">
                            {data && data.author ? `by ${data.author}` : null}
                        </Typography>
                        <Typography style={{
                            marginBottom: 24
                        }} variant="body1">
                            {data && data.description}
                        </Typography>

                        <Grid style={{
                            marginBottom: 64,
                            display: 'flex',
                            flexDirection: 'column'
                        }} container item xs={6}>
                            <Typography variant="body2" gutterBottom>
                                Goto Gamepicker 64 - A browser widget for picking random games!
                            </Typography>
                            <Grid container item xs={3}>
                                <a
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href={`${process.env.REACT_APP_GAMEPICKER_URL}?id=${id}`}>
                                    <Button
                                        style={{
                                            width: 200,
                                            height: '100%'
                                        }}
                                        variant="outlined" color="secondary"
                                        className={classes.secondaryButton}>
                                        Game Picker 64
                                    </Button>
                                </a>
                            </Grid>

                        </Grid>

                        <Typography
                            variant="h6" gutterBottom>
                            {`Total Games: ${data && data.games.length}`}
                        </Typography>

                        <Grid container xs={12} item style={{
                            margin: 'auto',
                            display: 'flex',
                            gap: '22px',
                        }}>
                            {
                                data && data.games.map((game, i) => {
                                    return <GameCardItem key={i} game={game}/>
                                })
                            }
                        </Grid>


                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
};

export default ViewCollectionPage;